import React from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import { appName } from "../../components";

import * as Styled from "./index.styled";

interface Props {
  openInfoPopUp: () => void;
}

export function Header({ openInfoPopUp }: Props) {
  return (
    <Styled.Container>
      <Styled.Content>
              <Styled.Logo><Styled.LogoN>n</Styled.LogoN>ailed heardle</Styled.Logo>
      </Styled.Content>
    </Styled.Container>
  );
}
