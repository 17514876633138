import { Song } from "../types/song";
import { currentDate } from "../helpers/currentDate";
import { songs } from "./songs";

export { playTimes } from "./playTimes";
export { theme } from "./theme";

export function songList(): Song[] {
    const currentDay: string = (currentDate()).getFullYear() + '-' + ('0' + ((currentDate()).getMonth() + 1)).slice(-2) + '-' + ('0' + (currentDate()).getDate()).slice(-2);

    return songs;
}
