export const messagesOfTheDay = {
  "2022-09-21": "Happy Birthday to The Fragile!",
  "2022-10-20": "Happy Birthday to Pretty Hate Machine!",
  "2022-10-31": "🎃Happy Halloween!🎃",
  "2022-11-05": "Thanks Blake for the awesome mashup album!",
  "2023-04-01": "April Fools",
  "2023-05-13": "Happy Birthday Jess! 💕",
  "2023-05-28": "Congrats on the release, Galvinatrix!",
  "2023-08-30": "Happy Birthday to Hesitation Marks!",
}
