// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/bahnschrift.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

body {
    font-family: 'Bahnschrift';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #25160F;
    color: #DFDF98;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,qFAA4E;AAC9E;;AAEA;IACI,0BAA0B;IAC1B,mCAAmC;IACnC,kCAAkC;IAClC,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":["@font-face {\n  font-family: \"Bahnschrift\";\n  src: local(\"Bahnschrift\"), url(\"./fonts/bahnschrift.ttf\") format(\"truetype\");\n}\n\nbody {\n    font-family: 'Bahnschrift';\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    background-color: #25160F;\n    color: #DFDF98;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
