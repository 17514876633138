import styled from "styled-components";

export const Buttons = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TodaysDate = styled.div`
  font-size: 1rem;
  padding: 12.5px 20px;
  opacity: 0.7;
`
