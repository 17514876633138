import React from "react";

import { GuessType } from "../../types/guess";
import { Song } from "../../types/song";

import * as Styled from "./index.styled";

interface Props {
  guess: GuessType;
  isCorrect: boolean | undefined;
  active: boolean;
  todaysSolution: Song;
}

export function Guess({ guess, isCorrect, active, todaysSolution }: Props) {
  const { song, skipped } = guess;
  const [text, setText] = React.useState<string>("");


  React.useEffect(() => {
    if (song) {
      const ghostsHint = ghostsComparison(`${song.name}`, todaysSolution);
      setText(`${song.artist} - ${song.name}${ghostsHint}`);
    } else if (skipped) {
      setText("Skipped");
    } else {
      setText("");
    }
  }, [guess]);

  return (
    <Styled.Container active={active} isCorrect={isCorrect}>
      <Styled.Text>{text}</Styled.Text>
    </Styled.Container>
  );
}

function ghostsComparison(guessName: string, todaysSolution: Song) {
  let re: RegExp = /([0-9]{1,2}) Ghosts/;
  const isGuessGhost = re.test(guessName);
  const isSolutionGhost = re.test(todaysSolution.name);
  if (isGuessGhost && isSolutionGhost) {
    const guessNumberStr = guessName.match(re)?.[1];
    const solutionNumberStr = todaysSolution.name.match(re)?.[1];
    if (guessNumberStr && solutionNumberStr) {
      const guessNumber: number = +guessNumberStr;
      const solutionNumber: number = +solutionNumberStr;
      if (solutionNumber > guessNumber) {
        return " --- Too Low!";
      }
      if (solutionNumber < guessNumber) {
        return " --- Too High!";
      }
    }
  }
  return "";
}