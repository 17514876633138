export const theme = {
  border: "#F1F7ED",
  border100: "#5C5C5C",

  text: "#DFDF98",
  background100: "#9A7447",

  green: "#911E23",
  red: "#FF0000",
  gray: "#E6E6E6",
} as const;
