import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  border-color: ${({ theme }) => theme.border};
  border-bottom-width: 0.5px;
  border-bottom-style: solid;

  margin-bottom: 15px;
`;

export const Content = styled.div`
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 95%;
  }

  max-width: 650px;

  svg:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  a {
    color: ${({ theme }) => theme.text};
  }
`;

export const Logo = styled.h1`
  color: ${({ theme }) => theme.text};
  width: max-content;
  font-weight: 400;

  -webkit-touch-callout: none;
  user-select: none;
`;

export const LogoN = styled.div`
  -webkit-transform:rotateY(180deg);
  -moz-transform:rotateY(180deg);
  -o-transform:rotateY(180deg);
  -ms-transform:rotateY(180deg);
  unicode-bidi:bidi-override;
  direction:rtl;
  float:left;
`;
