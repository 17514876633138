import { songList } from "../constants";
import { currentDate } from "./currentDate";

const epochMs = new Date(2022, 2, 21).valueOf();
const now = currentDate().getTime();
const msInDay = 86400000;
const index = Math.floor((now - epochMs) / msInDay) - 175;

const todaysSong = songList()[index % songList().length];

export const todaysSolution = todaysSong
