import { songList } from "../constants";
import { Song } from "../types/song";

export function searchSong(searchTerm: string): Song[] {
    searchTerm = searchTerm.toLowerCase().replace(/\W/g, '');

    return songList()
        .map((song: Song) => {
            return {
                "artist": song.artist,
                "name": song.name,
                "youtubeId": song.youtubeId
            };
        })
        .filter((value, index, array) =>
            !array.filter((v, i) => JSON.stringify(value) == JSON.stringify(v) && i < index).length
        )
        .filter((song: Song) => {
            const songName = song.name.toLowerCase().replace(/\W/g, '');
            const songArtist = song.artist.toLowerCase().replace(/\W/g, '');

            if (songArtist.includes(searchTerm) || songName.includes(searchTerm)) {
                return song;
            }
        })
        .sort((a, b) =>
            a.artist.toLowerCase().localeCompare(b.artist.toLocaleLowerCase())
        )
        .slice(0, 5);
}