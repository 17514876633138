import { GuessType } from "../types/guess";
import { currentDate } from "./currentDate"

export function scoreToEmoji(guesses: GuessType[]): string {
    const emojis = {
        incorrect: "🟥",
        correct: "🟩",
        skip: "⬜",
        empty: "⬛️",
    };
    const prefix = `${currentDate().toLocaleDateString("en-US", { year: 'numeric', day: 'numeric', month: 'long' })}`;

    let scoreEmoji = "";

    guesses.forEach((guess: GuessType) => {
        if (guess.isCorrect === true) {
            scoreEmoji += emojis.correct;
        } else if (guess.skipped === true) {
            scoreEmoji += emojis.skip;
        } else if (guess.isCorrect === false) {
            scoreEmoji += emojis.incorrect;
        } else {
            scoreEmoji += emojis.empty;
        }
    });

    return `nailed heardle - ${prefix} \n ${scoreEmoji} \n https://nailedheardle.com`;
}
