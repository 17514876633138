import React, { useState, useEffect } from "react";

import { Song } from "../../types/song";
import { GuessType } from "../../types/guess";
import { scoreToEmoji } from "../../helpers";
import { currentDate } from "../../helpers/currentDate";
import { messagesOfTheDay } from "../../constants/motd"

import { Button } from "../Button";
import { YouTube } from "../YouTube";

import * as Styled from "./index.styled";

interface Props {
    didGuess: boolean;
    currentTry: number;
    todaysSolution: Song;
    guesses: GuessType[];
}

export function Result({
    didGuess,
    todaysSolution,
    guesses,
    currentTry,
}: Props) {
    const hoursToNextDay = Math.floor(
        (new Date(new Date().setHours(24, 0, 0, 0)).getTime() -
            new Date().getTime()) /
        1000 /
        60 /
        60
    );
    const minutesToNextDay = Math.floor(
        (new Date(new Date().setHours(24, 0, 0, 0)).getTime() -
            new Date().getTime()) /
        1000 /
        60
    ) % 60;
    const timeRemaining = hoursToNextDay === 0 ? `${minutesToNextDay} minutes` : `${hoursToNextDay} hours and ${minutesToNextDay} minutes`;

    const currentDay: string = (currentDate()).getFullYear() + '-' + ('0' + ((currentDate()).getMonth() + 1)).slice(-2) + '-' + ('0' + (currentDate()).getDate()).slice(-2);
    const motd = messagesOfTheDay[currentDay as keyof typeof messagesOfTheDay];


    const motdRender = motd === undefined ? <> </> : <Styled.ResultTitle>🎉{motd}🎉</Styled.ResultTitle>;

    var wrongGuesses = 0;
    var skips = 0;
    var wasCorrect = 0;

    guesses.forEach((guess: GuessType) => {
        if (guess.isCorrect === true) {
            wasCorrect = 1;
        } else if (guess.skipped === true) {
            skips++;
        } else if (guess.isCorrect === false) {
            wrongGuesses++;
        }
    });

    let xhr = new XMLHttpRequest();
    const trackingUrl = `/game/date/${currentDay}/correct/${wasCorrect}/skipCount/${skips}/wrongGuessCount/${wrongGuesses}`;
    xhr.open('GET', trackingUrl);
    xhr.send();

    const startPosition = todaysSolution.start !== undefined ? todaysSolution.start : 0;

    if (didGuess) {
        const copyResult = React.useCallback(() => {
            navigator.clipboard.writeText(scoreToEmoji(guesses));
        }, [guesses]);

        const triesConjugation = currentTry === 1 ? "try" : "tries";
        return (
            <>
                <Styled.ResultTitle>Nailed it in {currentTry} {triesConjugation}!</Styled.ResultTitle>
                <Styled.SongTitle>
                    {todaysSolution.name}
                </Styled.SongTitle>
                {motdRender}
                <YouTube id={todaysSolution.youtubeId} start={startPosition} />
                <Button onClick={copyResult} variant="green">
                    Copy Results to Clipboard
                </Button>
                <Styled.TimeToNext>
                    Remember to come back tomorrow! Next song in {timeRemaining}.
                </Styled.TimeToNext>
            </>
        );
    } else {
        const copyResult = React.useCallback(() => {
            navigator.clipboard.writeText(scoreToEmoji(guesses));
        }, [guesses]);
        return (
            <>
                <Styled.ResultTitle>You tried. You gave up.</Styled.ResultTitle>
                <Styled.SongTitle>
                    It was: {todaysSolution.name}
                </Styled.SongTitle>
                {motdRender}
                <YouTube id={todaysSolution.youtubeId} start={startPosition} />
                <Button onClick={copyResult} variant="green">
                    Copy Results to Clipboard
                </Button>
                <Styled.TimeToNext>
                    Try again tomorrow! Next song in {timeRemaining}.
                </Styled.TimeToNext>
            </>
        );
    }
}
