export function currentDate(): Date {
    const millisInDay = 1000 * 60 * 60 * 24;

    const offsetStr = new URLSearchParams(window.location.search).get('offset');
    if (offsetStr != null) {
        return new Date(Date.now() + (Number(offsetStr) * millisInDay));
    }

    const yesterdayStr = new URLSearchParams(window.location.search).get('yesterday');
    if (yesterdayStr != null) {
        return new Date(Date.now() - (millisInDay));
    }

    const dateStr = new URLSearchParams(window.location.search).get('date');
    if (dateStr != null) {
        const tzOffsetMillis = (new Date()).getTimezoneOffset() * 60 * 1000;
        const passedDate = new Date((new Date(dateStr)).getTime() + tzOffsetMillis);
        if (passedDate.getTime() <= Date.now()) {
            return passedDate;
        }
    }

    const debugDateStr = new URLSearchParams(window.location.search).get('debugDateP');
    if (debugDateStr != null) {
        const tzOffsetMillis = (new Date()).getTimezoneOffset() * 60 * 1000;
        const passedDate = new Date((new Date(debugDateStr)).getTime() + tzOffsetMillis);
        return passedDate;
    }

    return new Date();
}
