export const songs =
[
    {
        "date": "09/12/22",
        "artist": "Nine Inch Nails",
        "name": "Pinion",
        "youtubeId": "tOxLbvHFbh4",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "09/13/22",
        "artist": "Nine Inch Nails",
        "name": "Beside You In Time",
        "youtubeId": "dO12h46EOyQ",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "09/14/22",
        "artist": "Nine Inch Nails",
        "name": "Gave Up",
        "youtubeId": "EDgt-hw8A40",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "09/15/22",
        "artist": "Nine Inch Nails",
        "name": "Burning Bright (Field on Fire)",
        "youtubeId": "gm4tn8znQE0",
        "halo": "Not The Actual Events",
        "start": 0
    },
    {
        "date": "09/16/22",
        "artist": "Nine Inch Nails",
        "name": "The Big Come Down",
        "youtubeId": "9gg2p7_PnTQ",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "09/17/22",
        "artist": "Nine Inch Nails",
        "name": "Down In It (Skin)",
        "youtubeId": "H2u-DsqviXk",
        "halo": "Down In It",
        "start": 0
    },
    {
        "date": "09/18/22",
        "artist": "Nine Inch Nails",
        "name": "A Violet Fluid",
        "youtubeId": "rr5VtFy5idA",
        "halo": "March of the Pigs",
        "start": 0
    },
    {
        "date": "09/19/22",
        "artist": "Nine Inch Nails",
        "name": "Running",
        "youtubeId": "ag6CsnIbAnI",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "09/20/22",
        "artist": "Nine Inch Nails",
        "name": "The Persistence Of Loss",
        "youtubeId": "-kz9YRgugls",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "09/21/22",
        "artist": "Nine Inch Nails",
        "name": "Somewhat Damaged",
        "youtubeId": "JX-Vlrpv8qI",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "09/22/22",
        "artist": "Nine Inch Nails",
        "name": "The Good Soldier",
        "youtubeId": "9lzJ4LvYOFk",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "09/23/22",
        "artist": "Nine Inch Nails",
        "name": "Less Than",
        "youtubeId": "gDV-dOvqKzQ",
        "halo": "Add Violence",
        "start": 0
    },
    {
        "date": "09/24/22",
        "artist": "Nine Inch Nails",
        "name": "Metal",
        "youtubeId": "fVXNYatdz48",
        "halo": "Things Falling Apart",
        "start": 0
    },
    {
        "date": "09/25/22",
        "artist": "Nine Inch Nails",
        "name": "The Cursed Clock",
        "youtubeId": "bFpOy-6cirQ",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "09/26/22",
        "artist": "Nine Inch Nails",
        "name": "Reptile",
        "youtubeId": "4d64sKlm-Wo",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "09/27/22",
        "artist": "Nine Inch Nails",
        "name": "Happiness In Slavery",
        "youtubeId": "mQ2-SkLfldk",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "09/28/22",
        "artist": "Nine Inch Nails",
        "name": "Home",
        "youtubeId": "XaSZ2myWIGM",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "09/29/22",
        "artist": "Nine Inch Nails",
        "name": "You Know What You Are?",
        "youtubeId": "SmTN6-oIQwQ",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "09/30/22",
        "artist": "Nine Inch Nails",
        "name": "I Would for You",
        "youtubeId": "lJQs-ShfsMc",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "10/01/22",
        "artist": "Nine Inch Nails",
        "name": "Was It Worth It?",
        "youtubeId": "8Y5mDJdTXUw",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "10/02/22",
        "artist": "Nine Inch Nails",
        "name": "31 Ghosts IV",
        "youtubeId": "84bV_2Qvhe4",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "10/03/22",
        "artist": "Nine Inch Nails",
        "name": "Ringfinger",
        "youtubeId": "Xbp0qm-x1v8",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "10/04/22",
        "artist": "Nine Inch Nails",
        "name": "(+Appendage)",
        "youtubeId": "zCD_2soPhVc",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "10/05/22",
        "artist": "Nine Inch Nails",
        "name": "Closer",
        "youtubeId": "9-5IIx4R6E8",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "10/06/22",
        "artist": "Nine Inch Nails",
        "name": "Last",
        "youtubeId": "DzkBmJGGwdA",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "10/07/22",
        "artist": "Nine Inch Nails",
        "name": "Even Deeper",
        "youtubeId": "hwz86XHCMdU",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "10/08/22",
        "artist": "Nine Inch Nails",
        "name": "Trust Fades",
        "youtubeId": "szB8ESdAgds",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "10/09/22",
        "artist": "Nine Inch Nails",
        "name": "Can I Stay Here?",
        "youtubeId": "ss7iPXdAvSM",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "10/10/22",
        "artist": "Nine Inch Nails",
        "name": "The New Flesh",
        "youtubeId": "1bl7QovPblA",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "10/11/22",
        "artist": "Nine Inch Nails",
        "name": "Vessel",
        "youtubeId": "pUeLrzfmtUk",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "10/12/22",
        "artist": "Nine Inch Nails",
        "name": "Over and Out",
        "youtubeId": "h-XlN3N2fis",
        "halo": "Bad Witch",
        "start": 0
    },
    {
        "date": "10/13/22",
        "artist": "Nine Inch Nails",
        "name": "Love Is Not Enough",
        "youtubeId": "xxufoj-GuRk",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "10/14/22",
        "artist": "Nine Inch Nails",
        "name": "The Only Time",
        "youtubeId": "EWc00wZWG_k",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "10/15/22",
        "artist": "Nine Inch Nails",
        "name": "The Downward Spiral (The Bottom)",
        "youtubeId": "wBNhZkQk4xE",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "10/16/22",
        "artist": "Nine Inch Nails",
        "name": "18 Ghosts II",
        "youtubeId": "JM-SXW3T5D4",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "10/17/22",
        "artist": "Nine Inch Nails",
        "name": "Kinda I Want To",
        "youtubeId": "1UaqcWBtT-o",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "10/18/22",
        "artist": "Nine Inch Nails",
        "name": "Wish",
        "youtubeId": "IU5R8EKsomA",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "10/19/22",
        "artist": "Nine Inch Nails",
        "name": "Dead Souls",
        "youtubeId": "sDHqywS6un0",
        "halo": "Soundtrack NIN",
        "start": 0
    },
    {
        "date": "10/20/22",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole",
        "youtubeId": "ghIRu6UvQPc",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "10/21/22",
        "artist": "Nine Inch Nails",
        "name": "Physical (You're So)",
        "youtubeId": "nOvX75ck1_E",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "10/22/22",
        "artist": "Nine Inch Nails",
        "name": "March Of The Fuckheads",
        "youtubeId": "BrS3FXblpaU",
        "halo": "Closer To God",
        "start": 0
    },
    {
        "date": "10/23/22",
        "artist": "Nine Inch Nails",
        "name": "Still Right Here",
        "youtubeId": "OgPJycJ6Img",
        "halo": "Ghosts V: Together",
        "start": 0
    },
    {
        "date": "10/24/22",
        "artist": "Nine Inch Nails",
        "name": "Something I Can Never Have",
        "youtubeId": "AiIu1v988W0",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "10/25/22",
        "artist": "Nine Inch Nails",
        "name": "Zero-Sum",
        "youtubeId": "AMqG4uWmqzs",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "10/26/22",
        "artist": "Nine Inch Nails",
        "name": "She's Gone Away",
        "youtubeId": "r2bL7DU21Wg",
        "halo": "Not The Actual Events",
        "start": 0
    },
    {
        "date": "10/27/22",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds",
        "youtubeId": "qn7HvnMJZd4",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "10/28/22",
        "artist": "Nine Inch Nails",
        "name": "Me, I'm Not",
        "youtubeId": "QW8QtHxbbzI",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "10/29/22",
        "artist": "Nine Inch Nails",
        "name": "The Worriment Waltz",
        "youtubeId": "tEwZKJ9HYJA",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "10/30/22",
        "artist": "Nine Inch Nails",
        "name": "Closer To God",
        "youtubeId": "TmWShm6JPw0",
        "halo": "Closer To God",
        "start": 0
    },
    {
        "date": "10/31/22",
        "artist": "Trent Reznor & Atticus Ross",
        "name": "Theme to John Carpenter's Halloween",
        "youtubeId": "DAbDvqPFXvQ",
        "halo": "TRAR",
        "start": 117
    },
    {
        "date": "11/01/22",
        "artist": "Nine Inch Nails",
        "name": "Play the Goddamned Part",
        "youtubeId": "85UgvBkMfr8",
        "halo": "Bad Witch",
        "start": 0
    },
    {
        "date": "11/02/22",
        "artist": "Nine Inch Nails",
        "name": "March Of The Pigs",
        "youtubeId": "ojk-YRMXSdc",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "11/03/22",
        "artist": "Nine Inch Nails",
        "name": "Sanctified",
        "youtubeId": "pIyTpWyeYy0",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "11/04/22",
        "artist": "Nine Inch Nails",
        "name": "The Fragile",
        "youtubeId": "BO-iKVlPz8I",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "11/05/22",
        "artist": "Blake",
        "name": "The Big Burn Down",
        "youtubeId": "KjuZpg_g7co",
        "halo": "Troll",
        "start": 0
    },
    {
        "date": "11/06/22",
        "artist": "Nine Inch Nails",
        "name": "Hope We Can Again",
        "youtubeId": "BLj7S1QShdk",
        "halo": "Ghosts V: Together",
        "start": 0
    },
    {
        "date": "11/07/22",
        "artist": "Nine Inch Nails",
        "name": "Echoplex",
        "youtubeId": "_c7ixDZj9DA",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "11/08/22",
        "artist": "Nine Inch Nails",
        "name": "Not So Pretty Now",
        "youtubeId": "LWdn9QYN4ag",
        "halo": "NINJA 2009 Summer Tour EP",
        "start": 0
    },
    {
        "date": "11/09/22",
        "artist": "Nine Inch Nails",
        "name": "Disappointed",
        "youtubeId": "bOXXayxeSlY",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "11/10/22",
        "artist": "Nine Inch Nails",
        "name": "Dear World,",
        "youtubeId": "9bx-xlof6q4",
        "halo": "Not The Actual Events",
        "start": 0
    },
    {
        "date": "11/11/22",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug",
        "youtubeId": "pa5YJRcfCfM",
        "halo": "Soundtrack NIN",
        "start": 0
    },
    {
        "date": "11/12/22",
        "artist": "Nine Inch Nails",
        "name": "Temp Fix",
        "youtubeId": "mAeT3ldMayQ",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "11/13/22",
        "artist": "Nine Inch Nails",
        "name": "Heresy (Blind)",
        "youtubeId": "VgIVcpWmFDc",
        "halo": "Closer To God",
        "start": 0
    },
    {
        "date": "11/14/22",
        "artist": "Nine Inch Nails",
        "name": "1,000,000",
        "youtubeId": "WtPDYuowNbk",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "11/15/22",
        "artist": "Nine Inch Nails",
        "name": "The Mark Has Been Made",
        "youtubeId": "yUCTlqExGyo",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "11/16/22",
        "artist": "Nine Inch Nails",
        "name": "Piggy (Nothing Can Stop Me Now)",
        "youtubeId": "32uqsXymi10",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "11/17/22",
        "artist": "Nine Inch Nails",
        "name": "This Isn't The Place",
        "youtubeId": "ws_wqrTifcg",
        "halo": "Add Violence",
        "start": 0
    },
    {
        "date": "11/18/22",
        "artist": "Nine Inch Nails",
        "name": "The Becoming",
        "youtubeId": "QWDsyvIfbak",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "11/19/22",
        "artist": "Nine Inch Nails",
        "name": "Closer (Deviation)",
        "youtubeId": "fEL2o8Ot9yA",
        "halo": "Closer To God",
        "start": 0
    },
    {
        "date": "11/20/22",
        "artist": "Nine Inch Nails",
        "name": "33 Ghosts IV",
        "youtubeId": "NuudxByC7V8",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "11/21/22",
        "artist": "Nine Inch Nails",
        "name": "Piggy",
        "youtubeId": "SSKp94wMEu0",
        "halo": "The Downward Spiral",
        "start": 1
    },
    {
        "date": "11/22/22",
        "artist": "Nine Inch Nails",
        "name": "Just Like You Imagined",
        "youtubeId": "OqWM-tXtoBU",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "11/23/22",
        "artist": "Nine Inch Nails",
        "name": "Ahead of Ourselves",
        "youtubeId": "4Ab1O-i4ep4",
        "halo": "Bad Witch",
        "start": 0
    },
    {
        "date": "11/24/22",
        "artist": "Nine Inch Nails",
        "name": "The March",
        "youtubeId": "XD76iFRIJ78",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "11/25/22",
        "artist": "Nine Inch Nails",
        "name": "Lights In The Sky",
        "youtubeId": "j-jWOBPN4UU",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "11/26/22",
        "artist": "Nine Inch Nails",
        "name": "19 Ghosts III",
        "youtubeId": "qYqXYbyON0Q",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "11/27/22",
        "artist": "Nine Inch Nails",
        "name": "Eraser (Polite)",
        "youtubeId": "Z1M57PB9Ybg",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "11/28/22",
        "artist": "Nine Inch Nails",
        "name": "Gunshots By Computer",
        "youtubeId": "A6qiLSRZDL8",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "11/29/22",
        "artist": "Nine Inch Nails",
        "name": "Deep",
        "youtubeId": "pEJOZITGpmI",
        "halo": "Soundtrack NIN",
        "start": 0
    },
    {
        "date": "11/30/22",
        "artist": "Nine Inch Nails",
        "name": "Underneath It All",
        "youtubeId": "pzXeJ762BBc",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "12/01/22",
        "artist": "Nine Inch Nails",
        "name": "Purest Feeling",
        "youtubeId": "OiJBPhJG3mQ",
        "halo": "Purest Feeling",
        "start": 0
    },
    {
        "date": "12/02/22",
        "artist": "Nine Inch Nails",
        "name": "That's What I Get",
        "youtubeId": "NU4wlbT4nBw",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "12/03/22",
        "artist": "Nine Inch Nails",
        "name": "Run Like Hell",
        "youtubeId": "_-BLflnCUv8",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "12/04/22",
        "artist": "Nine Inch Nails",
        "name": "28 Ghosts IV",
        "youtubeId": "5XipJzHD4s4",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/05/22",
        "artist": "Nine Inch Nails",
        "name": "Branches/Bones",
        "youtubeId": "I0KOy6H-s6w",
        "halo": "Not The Actual Events",
        "start": 0
    },
    {
        "date": "12/06/22",
        "artist": "Nine Inch Nails",
        "name": "While I'm Still Here",
        "youtubeId": "ckdZ7E-vP9M",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "12/07/22",
        "artist": "Nine Inch Nails",
        "name": "The Greater Good",
        "youtubeId": "3UF_uE3Cb5E",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "12/08/22",
        "artist": "Nine Inch Nails",
        "name": "Suck",
        "youtubeId": "p39Xx1cWxao",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "12/09/22",
        "artist": "Nine Inch Nails",
        "name": "The Collector",
        "youtubeId": "LWx8pOMpDJQ",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "12/10/22",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug (Plug)",
        "youtubeId": "5PB28tIW-mM",
        "halo": "The Perfect Drug Versions",
        "start": 0
    },
    {
        "date": "12/11/22",
        "artist": "Nine Inch Nails",
        "name": "27 Ghosts III",
        "youtubeId": "qz0_Sg4QvNU",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/12/22",
        "artist": "Nine Inch Nails",
        "name": "The Beginning Of The End",
        "youtubeId": "Ce5vC_fBasE",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "12/13/22",
        "artist": "Nine Inch Nails",
        "name": "Came Back Haunted",
        "youtubeId": "h6iKmf6nbLk",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "12/14/22",
        "artist": "Nine Inch Nails",
        "name": "Sin",
        "youtubeId": "5bwE5IU4pzA",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "12/15/22",
        "artist": "Nine Inch Nails",
        "name": "Adrift & At Peace",
        "youtubeId": "jRW50ziyfbY",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "12/16/22",
        "artist": "Nine Inch Nails",
        "name": "Only",
        "youtubeId": "VB8iRUqlpZw",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "12/17/22",
        "artist": "Nine Inch Nails",
        "name": "When It Happens (Don't Mind Me)",
        "youtubeId": "TLG5tWrdsOc",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "12/18/22",
        "artist": "Nine Inch Nails",
        "name": "9 Ghosts II",
        "youtubeId": "tbmj8e5HRkY",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/19/22",
        "artist": "Nine Inch Nails",
        "name": "Memorabilia",
        "youtubeId": "weuVPUtdjrY",
        "halo": "Closer To God",
        "start": 0
    },
    {
        "date": "12/20/22",
        "artist": "Nine Inch Nails",
        "name": "The Wretched",
        "youtubeId": "ANjQ8wI-W7g",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "12/21/22",
        "artist": "Nine Inch Nails",
        "name": "999,999",
        "youtubeId": "JOUBNVtg200",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "12/22/22",
        "artist": "Nine Inch Nails",
        "name": "I'm Not From This World",
        "youtubeId": "9fjbcSUSt9w",
        "halo": "Bad Witch",
        "start": 0
    },
    {
        "date": "12/23/22",
        "artist": "Nine Inch Nails",
        "name": "La Mer",
        "youtubeId": "dcIOInVS7jo",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "12/24/22",
        "artist": "Nine Inch Nails",
        "name": "Claustrophobia Machine",
        "youtubeId": "rssgoxLFNRc",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "12/25/22",
        "artist": "Nine Inch Nails",
        "name": "Maybe Just Once",
        "youtubeId": "Lo6o3KXuoao",
        "halo": "Purest Feeling",
        "start": 0
    },
    {
        "date": "12/26/22",
        "artist": "Nine Inch Nails",
        "name": "Discipline",
        "youtubeId": "wHxOiAz4NF8",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "12/27/22",
        "artist": "Nine Inch Nails",
        "name": "Heresy",
        "youtubeId": "6i8zwVIFxQg",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "12/28/22",
        "artist": "Nine Inch Nails",
        "name": "The Eater of Dreams",
        "youtubeId": "rxtAYx6auC4",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "12/29/22",
        "artist": "Nine Inch Nails",
        "name": "Fashion",
        "youtubeId": "klM9RlJfsVg",
        "halo": "Covers",
        "start": 0
    },
    {
        "date": "12/30/22",
        "artist": "Nine Inch Nails",
        "name": "The Great Below",
        "youtubeId": "HCORXBfRcNo",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "12/31/22",
        "artist": "Trent Reznor & Atticus Ross",
        "name": "New Year's Eve",
        "youtubeId": "-mtfX2M0mf4",
        "halo": "TRAR",
        "start": 0
    },
    {
        "date": "01/01/23",
        "artist": "Nine Inch Nails",
        "name": "White Mask",
        "youtubeId": "lvhzHblMPS8",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "01/02/23",
        "artist": "Nine Inch Nails",
        "name": "Letting You",
        "youtubeId": "FGhnRVqrTWs",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "01/03/23",
        "artist": "Nine Inch Nails",
        "name": "Burn",
        "youtubeId": "UePiNA--ook",
        "halo": "Soundtrack NIN",
        "start": 0
    },
    {
        "date": "01/04/23",
        "artist": "Nine Inch Nails",
        "name": "Please",
        "youtubeId": "RU-q94bYNoY",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "01/05/23",
        "artist": "Nine Inch Nails",
        "name": "Black Noise",
        "youtubeId": "lvHL86ZSFY4",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "01/06/23",
        "artist": "Nine Inch Nails",
        "name": "Leaving Hope",
        "youtubeId": "R-aEUmz32f4",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "01/07/23",
        "artist": "Nine Inch Nails",
        "name": "Missing Places",
        "youtubeId": "h9D39DgQeVg",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "01/08/23",
        "artist": "Nine Inch Nails",
        "name": "32 Ghosts IV",
        "youtubeId": "GHkhwmSq9t8",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "01/09/23",
        "artist": "Nine Inch Nails",
        "name": "The Idea of You",
        "youtubeId": "32E6B8P3J8M",
        "halo": "Not The Actual Events",
        "start": 0
    },
    {
        "date": "01/10/23",
        "artist": "Nine Inch Nails",
        "name": "All Time Low",
        "youtubeId": "--kYVgXjoEk",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "01/11/23",
        "artist": "Nine Inch Nails",
        "name": "Capital G",
        "youtubeId": "4Qr2jh1HlWU",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "01/12/23",
        "artist": "Nine Inch Nails",
        "name": "I'm Afraid of Americans",
        "youtubeId": "P6axe4hBJyg",
        "halo": "Covers",
        "start": 0
    },
    {
        "date": "01/13/23",
        "artist": "Nine Inch Nails",
        "name": "Big Man With A Gun",
        "youtubeId": "Xe2rKjw8bww",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "01/14/23",
        "artist": "Nine Inch Nails",
        "name": "Eraser (Denial; Realization)",
        "youtubeId": "XQUVfg93ERI",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "01/15/23",
        "artist": "Nine Inch Nails",
        "name": "31 Ghosts IV",
        "youtubeId": "84bV_2Qvhe4",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "01/16/23",
        "artist": "Nine Inch Nails",
        "name": "In Two",
        "youtubeId": "9uzYmoDRKsI",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "01/17/23",
        "artist": "Nine Inch Nails",
        "name": "10 Miles High",
        "youtubeId": "hmPQDbbgA1Y",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "01/18/23",
        "artist": "Nine Inch Nails",
        "name": "The Way Out Is Through",
        "youtubeId": "_WVA92lnty0",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "01/19/23",
        "artist": "Nine Inch Nails",
        "name": "Head Down",
        "youtubeId": "pq_wwGSFJlI",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "01/20/23",
        "artist": "Nine Inch Nails",
        "name": "The Great Destroyer",
        "youtubeId": "erfTFe5tSWY",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "01/21/23",
        "artist": "Nine Inch Nails",
        "name": "26 Ghosts III",
        "youtubeId": "M_0cZ658PO0",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "01/22/23",
        "artist": "Nine Inch Nails",
        "name": "Your New Normal",
        "youtubeId": "1Hx0k19H-fM",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "01/23/23",
        "artist": "Nine Inch Nails",
        "name": "The Four of Us Are Dying",
        "youtubeId": "_aaqlfVLT1Y",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "01/24/23",
        "artist": "Nine Inch Nails",
        "name": "Complication",
        "youtubeId": "ls9Gyv_v-G8",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "01/25/23",
        "artist": "Nine Inch Nails",
        "name": "Getting Smaller",
        "youtubeId": "oZT8hU3X15U",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "01/26/23",
        "artist": "Nine Inch Nails",
        "name": "Every Day Is Exactly The Same",
        "youtubeId": "HEdzyFJLRbc",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "01/27/23",
        "artist": "Nine Inch Nails",
        "name": "The Background World",
        "youtubeId": "nDIjTaPt9co",
        "halo": "Add Violence",
        "start": 0
    },
    {
        "date": "01/28/23",
        "artist": "Nine Inch Nails",
        "name": "One Way to Get There",
        "youtubeId": "fX81Ejkkgas",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "01/29/23",
        "artist": "Nine Inch Nails",
        "name": "20 Ghosts III",
        "youtubeId": "m6Usk1eoQcM",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "01/30/23",
        "artist": "Nine Inch Nails",
        "name": "Another Version Of The Truth",
        "youtubeId": "dJae6JW7LLQ",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "01/31/23",
        "artist": "Nine Inch Nails",
        "name": "All The Love In The World",
        "youtubeId": "TkUrZvbh9bU",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "02/01/23",
        "artist": "Nine Inch Nails",
        "name": "And All That Could Have Been",
        "youtubeId": "2U0flA_Yp64",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "02/02/23",
        "artist": "Nine Inch Nails",
        "name": "I'm Looking Forward To Joining You, Finally",
        "youtubeId": "MBff4D18Tck",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "02/03/23",
        "artist": "Nine Inch Nails",
        "name": "The Warning",
        "youtubeId": "EqAD4iq87Os",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "02/04/23",
        "artist": "Nine Inch Nails",
        "name": "Not What It Seems Like",
        "youtubeId": "nc596eI4BqI",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "02/05/23",
        "artist": "Nine Inch Nails",
        "name": "15 Ghosts II",
        "youtubeId": "EJ51kW684FM",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "02/06/23",
        "artist": "Nine Inch Nails",
        "name": "Sunspots",
        "youtubeId": "dOJpXttqA3c",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "02/07/23",
        "artist": "Nine Inch Nails",
        "name": "Copy of A",
        "youtubeId": "zirr0LcHdDw",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "02/08/23",
        "artist": "Nine Inch Nails",
        "name": "Everything",
        "youtubeId": "2l0swKptAqc",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "02/09/23",
        "artist": "Nine Inch Nails",
        "name": "Meet Your Master",
        "youtubeId": "P9MqBx3nc0M",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "02/10/23",
        "artist": "Nine Inch Nails",
        "name": "Non-Entity",
        "youtubeId": "Bg76mSSzErs",
        "halo": "NINJA 2009 Summer Tour EP",
        "start": 0
    },
    {
        "date": "02/11/23",
        "artist": "Nine Inch Nails",
        "name": "So Tired",
        "youtubeId": "qtrZWKOhQSw",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "02/12/23",
        "artist": "Nine Inch Nails",
        "name": "35 Ghosts IV",
        "youtubeId": "0p0IbHYMOxM",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "02/13/23",
        "artist": "Nine Inch Nails",
        "name": "God Given",
        "youtubeId": "FjFGXnKG3Rs",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "02/14/23",
        "artist": "Nine Inch Nails",
        "name": "The Lovers",
        "youtubeId": "S0Gmxdiy0d0",
        "halo": "Add Violence",
        "start": 0
    },
    {
        "date": "02/15/23",
        "artist": "Nine Inch Nails",
        "name": "I Do Not Want This",
        "youtubeId": "kb5_hIuEk8g",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "02/16/23",
        "artist": "Nine Inch Nails",
        "name": "Satellite",
        "youtubeId": "Tqymo0sVUwE",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "02/17/23",
        "artist": "Nine Inch Nails",
        "name": "Ruiner",
        "youtubeId": "SO4p9DeaCkw",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "02/18/23",
        "artist": "Nine Inch Nails",
        "name": "3 Ghosts I",
        "youtubeId": "DApuMNoN3ME",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "02/19/23",
        "artist": "Nine Inch Nails",
        "name": "Out In The Open",
        "youtubeId": "S3OXjwnZ29U",
        "halo": "Ghosts V: Together",
        "start": 0
    },
    {
        "date": "02/20/23",
        "artist": "Nine Inch Nails",
        "name": "Ripe (With Decay)",
        "youtubeId": "HkrUlSKRQRo",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "02/21/23",
        "artist": "Nine Inch Nails",
        "name": "God Break Down The Door",
        "youtubeId": "5gyOKTOcCyM",
        "halo": "Bad Witch",
        "start": 0
    },
    {
        "date": "02/22/23",
        "artist": "Nine Inch Nails",
        "name": "Right Where It Belongs v2",
        "youtubeId": "vxrf4ZzzrA8",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "02/23/23",
        "artist": "Nine Inch Nails",
        "name": "Not Anymore",
        "youtubeId": "AJztazY65Mw",
        "halo": "Add Violence",
        "start": 0
    },
    {
        "date": "02/24/23",
        "artist": "Nine Inch Nails",
        "name": "The Line Begins To Blur",
        "youtubeId": "lmygz88y348",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "02/25/23",
        "artist": "Nine Inch Nails",
        "name": "Another Crashed Car",
        "youtubeId": "aj0pbIJ0aL4",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "02/26/23",
        "artist": "Nine Inch Nails",
        "name": "Reptilian",
        "youtubeId": "uG2JzNZeNeI",
        "halo": "March of the Pigs",
        "start": 0
    },
    {
        "date": "02/27/23",
        "artist": "Nine Inch Nails",
        "name": "The Great Collapse",
        "youtubeId": "wHy0RqWGnDo",
        "halo": "Things Falling Apart",
        "start": 1
    },
    {
        "date": "02/28/23",
        "artist": "Nine Inch Nails",
        "name": "With Teeth",
        "youtubeId": "c1uaGkwmDa0",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "03/01/23",
        "artist": "Nine Inch Nails",
        "name": "Pilgrimage",
        "youtubeId": "xJUJ-qNdsf8",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "03/02/23",
        "artist": "Nine Inch Nails",
        "name": "The Downward Spiral",
        "youtubeId": "f45yDddYePg",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "03/03/23",
        "artist": "Nine Inch Nails",
        "name": "My Violent Heart",
        "youtubeId": "hqRk5kXROS4",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "03/04/23",
        "artist": "Nine Inch Nails",
        "name": "36 Ghosts IV",
        "youtubeId": "njI7b2eWljY",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "03/05/23",
        "artist": "Nine Inch Nails",
        "name": "Closer (Further Away)",
        "youtubeId": "IM1mFMwWL5Q",
        "halo": "Closer To God",
        "start": 0
    },
    {
        "date": "03/06/23",
        "artist": "Nine Inch Nails",
        "name": "Find My Way",
        "youtubeId": "kw49JbrO-rc",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "03/07/23",
        "artist": "Nine Inch Nails",
        "name": "Quake Theme",
        "youtubeId": "Mv5Jvr2Fp48",
        "halo": "Soundtrack NIN",
        "start": 0
    },
    {
        "date": "03/08/23",
        "artist": "Nine Inch Nails",
        "name": "A Warm Place",
        "youtubeId": "Yld7Fs-VfRE",
        "halo": "The Downward Spiral",
        "start": 1
    },
    {
        "date": "03/09/23",
        "artist": "Nine Inch Nails",
        "name": "Corona Radiata",
        "youtubeId": "BrhzPhTSxfg",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "03/10/23",
        "artist": "Nine Inch Nails",
        "name": "Shit Mirror",
        "youtubeId": "yeqjz5mXrLQ",
        "halo": "Bad Witch",
        "start": 0
    },
    {
        "date": "03/11/23",
        "artist": "Nine Inch Nails",
        "name": "Almost Dawn",
        "youtubeId": "hZ_zzUGdp8g",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "03/12/23",
        "artist": "Nine Inch Nails",
        "name": "14 Ghosts II",
        "youtubeId": "qibx62koZr8",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "03/13/23",
        "artist": "Nine Inch Nails",
        "name": "Survivalism",
        "youtubeId": "Kr-uzvTBAlY",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "03/14/23",
        "artist": "Nine Inch Nails",
        "name": "The Day The World Went Away (Still)",
        "youtubeId": "ect2zHjuYb4",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "03/15/23",
        "artist": "Nine Inch Nails",
        "name": "Starfuckers, Inc. (Version) - Adrian Sherwood",
        "youtubeId": "xN7RJojsP1c",
        "halo": "Things Falling Apart",
        "start": 0
    },
    {
        "date": "03/16/23",
        "artist": "Nine Inch Nails",
        "name": "We're In This Together",
        "youtubeId": "6naYkchULZ4",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "03/17/23",
        "artist": "Nine Inch Nails",
        "name": "Get Down, Make Love",
        "youtubeId": "Sp_uMrVpgoU",
        "halo": "Sin",
        "start": 0
    },
    {
        "date": "03/18/23",
        "artist": "Nine Inch Nails",
        "name": "The Warning (Stefan Goodchild featuring Doudou N'Diaye Rose)",
        "youtubeId": "_nzfkBklGYA",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "03/19/23",
        "artist": "Nine Inch Nails",
        "name": "Self Destruction, Part Two",
        "youtubeId": "g6UPjmuFXFs",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "03/20/23",
        "artist": "Nine Inch Nails",
        "name": "Right Where It Belongs",
        "youtubeId": "puk5eeqaGIk",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "03/21/23",
        "artist": "Nine Inch Nails",
        "name": "Demon Seed",
        "youtubeId": "eICUTG7Oyik",
        "halo": "The Slip",
        "start": 0
    },
    {
        "date": "03/22/23",
        "artist": "Nine Inch Nails",
        "name": "The Great Destroyer (modwheelmood)",
        "youtubeId": "TasujsNonuY",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "03/23/23",
        "artist": "Nine Inch Nails",
        "name": "Starfuckers, Inc.",
        "youtubeId": "dxjry1KS-pg",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "03/24/23",
        "artist": "Nine Inch Nails",
        "name": "The Frail (Version)",
        "youtubeId": "Vr3-EZGBTOU",
        "halo": "Things Falling Apart",
        "start": 0
    },
    {
        "date": "03/25/23",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug (Nine Inch Nails Remix)",
        "youtubeId": "m7WyWMUtmkg",
        "halo": "The Perfect Drug Versions",
        "start": 0
    },
    {
        "date": "03/26/23",
        "artist": "Nine Inch Nails",
        "name": "Kinda I Want To (Purest Feeling)",
        "youtubeId": "QUxJmW9FHPQ",
        "halo": "Purest Feeling",
        "start": 0
    },
    {
        "date": "03/27/23",
        "artist": "Nine Inch Nails",
        "name": "The Day The World Went Away (Quiet)",
        "youtubeId": "gWDnbyiMhaU",
        "halo": "The Day The World Went Away",
        "start": 0
    },
    {
        "date": "03/28/23",
        "artist": "Nine Inch Nails",
        "name": "Where Is Everybody?",
        "youtubeId": "uQJq-WJXUAU",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "03/29/23",
        "artist": "Nine Inch Nails",
        "name": "Terrible Lie",
        "youtubeId": "rSjPAwsEXMU",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "03/30/23",
        "artist": "Nine Inch Nails",
        "name": "The Frail",
        "youtubeId": "rOMcxTK7-0w",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "03/31/23",
        "artist": "Nine Inch Nails",
        "name": "Complications Of The Flesh",
        "youtubeId": "0Ctv5uYcI8M",
        "halo": "We're In This Together",
        "start": 0
    },
    {
        "date": "04/01/23",
        "artist": "Filter",
        "name": "Hey Man, Nice Shot",
        "youtubeId": "o9mJ82x_l-E",
        "halo": "Short Bus",
        "start": 0
    },
    {
        "date": "04/02/23",
        "artist": "Nine Inch Nails",
        "name": "Fist Fuck",
        "youtubeId": "StOsxMIOfCs",
        "halo": "Fixed",
        "start": 0
    },
    {
        "date": "04/03/23",
        "artist": "Nine Inch Nails",
        "name": "No, You Don't",
        "youtubeId": "IhCVl8Z63D8",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "04/04/23",
        "artist": "Nine Inch Nails",
        "name": "Beside You In Time",
        "youtubeId": "dO12h46EOyQ",
        "halo": "With Teeth",
        "start": 0
    },
    {
        "date": "04/05/23",
        "artist": "Nine Inch Nails",
        "name": "The Wretched (Version)",
        "youtubeId": "eymuazt6LGA",
        "halo": "Things Falling Apart",
        "start": 0
    },
    {
        "date": "04/06/23",
        "artist": "Nine Inch Nails",
        "name": "Mr. Self Destruct",
        "youtubeId": "zBFXFzqIjHM",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "04/07/23",
        "artist": "Nine Inch Nails",
        "name": "Sanctified (2013)",
        "youtubeId": "8GprBtBpUJs",
        "halo": "Unpublished Remixes",
        "start": 0
    },
    {
        "date": "04/08/23",
        "artist": "Nine Inch Nails",
        "name": "The Good Solder (Sam Fog)",
        "youtubeId": "_rwi5uqp9M8",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 3
    },
    {
        "date": "04/09/23",
        "artist": "Nine Inch Nails",
        "name": "Only (Richard X Mix)",
        "youtubeId": "kYm1ZpL1GyI",
        "halo": "Every Day Is Exactly The Same",
        "start": 0
    },
    {
        "date": "04/10/23",
        "artist": "Nine Inch Nails",
        "name": "The Becoming (Still)",
        "youtubeId": "zbuI5GO_zWo",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "04/11/23",
        "artist": "Nine Inch Nails",
        "name": "Help Me I Am In Hell",
        "youtubeId": "G2BAAYsqTww",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "04/12/23",
        "artist": "Nine Inch Nails",
        "name": "In This Twilight",
        "youtubeId": "aEYJVrGAlVY",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "04/13/23",
        "artist": "Nine Inch Nails",
        "name": "Eraser",
        "youtubeId": "Ep-E2jZScwI",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "04/14/23",
        "artist": "Nine Inch Nails",
        "name": "All The Pigs, All Lined Up",
        "youtubeId": "_grjBk4emFg",
        "halo": "March of the Pigs",
        "start": 0
    },
    {
        "date": "04/15/23",
        "artist": "Nine Inch Nails",
        "name": "7 Ghosts I",
        "youtubeId": "OJHABPMb7Uw",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "04/16/23",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug (The Orb)",
        "youtubeId": "t7bZr3R6660",
        "halo": "The Perfect Drug Versions",
        "start": 0
    },
    {
        "date": "04/17/23",
        "artist": "Nine Inch Nails",
        "name": "The Fragile (Still)",
        "youtubeId": "_roKpZGJ2aA",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "04/18/23",
        "artist": "Nine Inch Nails",
        "name": "HYPERPOWER!",
        "youtubeId": "qt-AqmOVH74",
        "halo": "Year Zero",
        "start": 0
    },
    {
        "date": "04/19/23",
        "artist": "Nine Inch Nails",
        "name": "Various Methods of Escape",
        "youtubeId": "Om0lCG1RjHQ",
        "halo": "Hesitation Marks",
        "start": 0
    },
    {
        "date": "04/20/23",
        "artist": "Nine Inch Nails",
        "name": "Gave Up",
        "youtubeId": "EDgt-hw8A40",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "04/21/23",
        "artist": "Nine Inch Nails",
        "name": "The Only Time (Purest Feeling)",
        "youtubeId": "3L49BHxdgSw",
        "halo": "Purest Feeling",
        "start": 0
    },
    {
        "date": "04/22/23",
        "artist": "Nine Inch Nails",
        "name": "Survivalism_OpalHeartClinic_Niggy_Tardust!(Escaped\u2026",
        "youtubeId": "iRk4FFJVVFc",
        "halo": "Survivalism",
        "start": 0
    },
    {
        "date": "04/23/23",
        "artist": "Nine Inch Nails",
        "name": "Every Day Is Exactly The Same (Sam Fog vs. Carlos D Mix)",
        "youtubeId": "AUn2RjA6t0I",
        "halo": "Every Day Is Exactly The Same",
        "start": 0
    },
    {
        "date": "04/24/23",
        "artist": "Nine Inch Nails",
        "name": "Something I Can Never Have (Still)",
        "youtubeId": "aT88tymia9w",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "04/25/23",
        "artist": "Nine Inch Nails",
        "name": "Pinion",
        "youtubeId": "tOxLbvHFbh4",
        "halo": "Broken",
        "start": 0
    },
    {
        "date": "04/26/23",
        "artist": "Nine Inch Nails",
        "name": "Where Is Everybody? (Version)",
        "youtubeId": "GEfhj5Pv_WA",
        "halo": "Things Falling Apart",
        "start": 1
    },
    {
        "date": "04/27/23",
        "artist": "Nine Inch Nails",
        "name": "Into The Void",
        "youtubeId": "Q8LEheSUTGE",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "04/28/23",
        "artist": "Nine Inch Nails",
        "name": "Twist",
        "youtubeId": "b2hqY_er7XQ",
        "halo": "Purest Feeling",
        "start": 0
    },
    {
        "date": "04/29/23",
        "artist": "Nine Inch Nails",
        "name": "Last (Butch Vig Remix)",
        "youtubeId": "u9u5x-nNFQA",
        "halo": "Fixed",
        "start": 0
    },
    {
        "date": "04/30/23",
        "artist": "Nine Inch Nails",
        "name": "With Faith",
        "youtubeId": "dcnRHT4ej9g",
        "halo": "Ghosts V: Together",
        "start": 0
    },
    {
        "date": "05/01/23",
        "artist": "Nine Inch Nails",
        "name": "The Day The World Went Away",
        "youtubeId": "TfKTgx15jag",
        "halo": "The Fragile",
        "start": 0
    },
    {
        "date": "05/02/23",
        "artist": "Nine Inch Nails",
        "name": "Gone, Still",
        "youtubeId": "y2JMUxxktGo",
        "halo": "Still",
        "start": 0
    },
    {
        "date": "05/03/23",
        "artist": "Nine Inch Nails",
        "name": "Slipping Away",
        "youtubeId": "Nmpa9GfZtHA",
        "halo": "Things Falling Apart",
        "start": 0
    },
    {
        "date": "05/04/23",
        "artist": "Nine Inch Nails",
        "name": "Down In It",
        "youtubeId": "uXdIc8TZKcA",
        "halo": "Pretty Hate Machine",
        "start": 0
    },
    {
        "date": "05/05/23",
        "artist": "Nine Inch Nails",
        "name": "Hurt",
        "youtubeId": "Ty-bLdf8Bsw",
        "halo": "The Downward Spiral",
        "start": 0
    },
    {
        "date": "05/06/23",
        "artist": "Nine Inch Nails",
        "name": "38 Ghosts",
        "youtubeId": "R_sn9CwApbQ",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "05/07/23",
        "artist": "Nine Inch Nails",
        "name": "Erased, Over, Out",
        "youtubeId": "fTe9r9v36Ss",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "05/08/23",
        "artist": "Nine Inch Nails",
        "name": "Mr. Self Destruct",
        "youtubeId": "zBFXFzqIjHM",
        "halo": "The Downward Spiral",
        "start": 141
    },
    {
        "date": "05/09/23",
        "artist": "Nine Inch Nails",
        "name": "Happiness In Slavery",
        "youtubeId": "mQ2-SkLfldk",
        "halo": "Broken",
        "start": 153
    },
    {
        "date": "05/10/23",
        "artist": "Nine Inch Nails",
        "name": "Survivalism",
        "youtubeId": "Kr-uzvTBAlY",
        "halo": "Year Zero",
        "start": 200
    },
    {
        "date": "05/11/23",
        "artist": "Nine Inch Nails",
        "name": "Various Methods of Escape",
        "youtubeId": "Om0lCG1RjHQ",
        "halo": "Hesitation Marks",
        "start": 195
    },
    {
        "date": "05/12/23",
        "artist": "Nine Inch Nails",
        "name": "Somewhat Damaged",
        "youtubeId": "JX-Vlrpv8qI",
        "halo": "The Fragile",
        "start": 201
    },
    {
        "date": "05/13/23",
        "artist": "Nine Inch Nails",
        "name": "Find My Way (Oneohtrix Point Never Remix)",
        "youtubeId": "J91IBtGE69I",
        "halo": "Hesitation Marks (Deluxe)",
        "start": 0
    },
    {
        "date": "05/14/23",
        "artist": "Nine Inch Nails",
        "name": "La Mer (Version)",
        "youtubeId": "KfGOxWYYEVY",
        "halo": "The Fragile Hillebrandt Mixes",
        "start": 0
    },
    {
        "date": "05/15/23",
        "artist": "Nine Inch Nails",
        "name": "In Two",
        "youtubeId": "9uzYmoDRKsI",
        "halo": "Hesitation Marks",
        "start": 205
    },
    {
        "date": "05/16/23",
        "artist": "Nine Inch Nails",
        "name": "HYPERPOWER!",
        "youtubeId": "qt-AqmOVH74",
        "halo": "Year Zero",
        "start": 70
    },
    {
        "date": "05/17/23",
        "artist": "Nine Inch Nails",
        "name": "The Collector",
        "youtubeId": "LWx8pOMpDJQ",
        "halo": "With Teeth",
        "start": 151
    },
    {
        "date": "05/18/23",
        "artist": "Nine Inch Nails",
        "name": "Right Where It Belongs",
        "youtubeId": "puk5eeqaGIk",
        "halo": "With Teeth",
        "start": 270
    },
    {
        "date": "05/19/23",
        "artist": "Nine Inch Nails",
        "name": "Running",
        "youtubeId": "ag6CsnIbAnI",
        "halo": "Hesitation Marks",
        "start": 204
    },
    {
        "date": "05/20/23",
        "artist": "Nine Inch Nails",
        "name": "Meet Your Master (The Faint)",
        "youtubeId": "PrVwjnXSuV0",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "05/21/23",
        "artist": "Nine Inch Nails",
        "name": "Your Touch",
        "youtubeId": "25f8pQUytDc",
        "halo": "Ghosts V: Together",
        "start": 0
    },
    {
        "date": "05/22/23",
        "artist": "Nine Inch Nails",
        "name": "In This Twilight",
        "youtubeId": "aEYJVrGAlVY",
        "halo": "Year Zero",
        "start": 176
    },
    {
        "date": "05/23/23",
        "artist": "Nine Inch Nails",
        "name": "The Downward Spiral",
        "youtubeId": "f45yDddYePg",
        "halo": "The Downward Spiral",
        "start": 82
    },
    {
        "date": "05/24/23",
        "artist": "Nine Inch Nails",
        "name": "Find My Way",
        "youtubeId": "kw49JbrO-rc",
        "halo": "Hesitation Marks",
        "start": 263
    },
    {
        "date": "05/25/23",
        "artist": "Nine Inch Nails",
        "name": "Not Anymore",
        "youtubeId": "AJztazY65Mw",
        "halo": "Add Violence",
        "start": 144
    },
    {
        "date": "05/26/23",
        "artist": "Nine Inch Nails",
        "name": "Disappointed",
        "youtubeId": "bOXXayxeSlY",
        "halo": "Hesitation Marks",
        "start": 192
    },
    {
        "date": "05/27/23",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds (Straight Mix)",
        "youtubeId": "fykzPs4ym0k",
        "halo": "Every Day Is Exactly The Same",
        "start": 0
    },
    {
        "date": "05/28/23",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole (Slate)",
        "youtubeId": "a_S0yJ2N78Y",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "05/29/23",
        "artist": "Nine Inch Nails",
        "name": "The Beginning Of The End",
        "youtubeId": "Ce5vC_fBasE",
        "halo": "Year Zero",
        "start": 133
    },
    {
        "date": "05/30/23",
        "artist": "Nine Inch Nails",
        "name": "Play the Goddamned Part",
        "youtubeId": "85UgvBkMfr8",
        "halo": "Bad Witch",
        "start": 245
    },
    {
        "date": "05/31/23",
        "artist": "Nine Inch Nails",
        "name": "The Frail",
        "youtubeId": "rOMcxTK7-0w",
        "halo": "The Fragile",
        "start": 98
    },
    {
        "date": "06/01/23",
        "artist": "Nine Inch Nails",
        "name": "Heresy",
        "youtubeId": "6i8zwVIFxQg",
        "halo": "The Downward Spiral",
        "start": 163
    },
    {
        "date": "06/02/23",
        "artist": "Nine Inch Nails",
        "name": "The Idea of You",
        "youtubeId": "32E6B8P3J8M",
        "halo": "Not The Actual Events",
        "start": 138
    },
    {
        "date": "06/03/23",
        "artist": "Nine Inch Nails",
        "name": "Screaming Slave",
        "youtubeId": "zObRlAb3JB8",
        "halo": "Fixed",
        "start": 0
    },
    {
        "date": "06/04/23",
        "artist": "Nine Inch Nails",
        "name": "Sin (Long)",
        "youtubeId": "Xqekts7OyOI",
        "halo": "Sin",
        "start": 0
    },
    {
        "date": "06/05/23",
        "artist": "Nine Inch Nails",
        "name": "Please",
        "youtubeId": "RU-q94bYNoY",
        "halo": "The Fragile",
        "start": 185
    },
    {
        "date": "06/06/23",
        "artist": "Nine Inch Nails",
        "name": "The Background World",
        "youtubeId": "nDIjTaPt9co",
        "halo": "Add Violence",
        "start": 343
    },
    {
        "date": "06/07/23",
        "artist": "Nine Inch Nails",
        "name": "The Lovers",
        "youtubeId": "S0Gmxdiy0d0",
        "halo": "Add Violence",
        "start": 195
    },
    {
        "date": "06/08/23",
        "artist": "Nine Inch Nails",
        "name": "The Fragile",
        "youtubeId": "BO-iKVlPz8I",
        "halo": "The Fragile",
        "start": 137
    },
    {
        "date": "06/09/23",
        "artist": "Nine Inch Nails",
        "name": "Terrible Lie",
        "youtubeId": "rSjPAwsEXMU",
        "halo": "Pretty Hate Machine",
        "start": 234
    },
    {
        "date": "06/10/23",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole (Clay)",
        "youtubeId": "Hv5eV6BvFcs",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "06/11/23",
        "artist": "Nine Inch Nails",
        "name": "Last Heard From",
        "youtubeId": "lraIakqFaAI",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "06/12/23",
        "artist": "Nine Inch Nails",
        "name": "The New Flesh",
        "youtubeId": "1bl7QovPblA",
        "halo": "The Fragile",
        "start": 140
    },
    {
        "date": "06/13/23",
        "artist": "Nine Inch Nails",
        "name": "Big Man With A Gun",
        "youtubeId": "Xe2rKjw8bww",
        "halo": "The Downward Spiral",
        "start": 60
    },
    {
        "date": "06/14/23",
        "artist": "Nine Inch Nails",
        "name": "Get Down, Make Love",
        "youtubeId": "Sp_uMrVpgoU",
        "halo": "Sin",
        "start": 190
    },
    {
        "date": "06/15/23",
        "artist": "Nine Inch Nails",
        "name": "Suck",
        "youtubeId": "p39Xx1cWxao",
        "halo": "Broken",
        "start": 231
    },
    {
        "date": "06/16/23",
        "artist": "Nine Inch Nails",
        "name": "The Fragile (Deconstructed)",
        "youtubeId": "Nljr9ZxUBZE",
        "halo": "The Fragile Hillebrandt Mixes",
        "start": 0
    },
    {
        "date": "06/17/23",
        "artist": "Nine Inch Nails",
        "name": "Together",
        "youtubeId": "8S42mc0mcrE",
        "halo": "Ghosts V: Together",
        "start": 0
    },
    {
        "date": "06/18/23",
        "artist": "Nine Inch Nails",
        "name": "Capital G (Switch)",
        "youtubeId": "IG4OsdagwXc",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 3
    },
    {
        "date": "06/19/23",
        "artist": "Nine Inch Nails",
        "name": "1,000,000",
        "youtubeId": "WtPDYuowNbk",
        "halo": "The Slip",
        "start": 208
    },
    {
        "date": "06/20/23",
        "artist": "Nine Inch Nails",
        "name": "Only",
        "youtubeId": "VB8iRUqlpZw",
        "halo": "With Teeth",
        "start": 236
    },
    {
        "date": "06/21/23",
        "artist": "Nine Inch Nails",
        "name": "Less Than",
        "youtubeId": "gDV-dOvqKzQ",
        "halo": "Add Violence",
        "start": 164
    },
    {
        "date": "06/22/23",
        "artist": "Nine Inch Nails",
        "name": "Gave Up",
        "youtubeId": "EDgt-hw8A40",
        "halo": "Broken",
        "start": 172
    },
    {
        "date": "06/23/23",
        "artist": "Nine Inch Nails",
        "name": "Lights In The Sky",
        "youtubeId": "j-jWOBPN4UU",
        "halo": "The Slip",
        "start": 150
    },
    {
        "date": "06/24/23",
        "artist": "Nine Inch Nails",
        "name": "The Beginning Of The End (Ladytron)",
        "youtubeId": "Bzl348HCN0w",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "06/25/23",
        "artist": "Nine Inch Nails",
        "name": "Down In It (Single)",
        "youtubeId": "u9IkjidIOIc",
        "halo": "Down In It",
        "start": 0
    },
    {
        "date": "06/26/23",
        "artist": "Nine Inch Nails",
        "name": "Deep",
        "youtubeId": "pEJOZITGpmI",
        "halo": "Soundtrack NIN",
        "start": 157
    },
    {
        "date": "06/27/23",
        "artist": "Nine Inch Nails",
        "name": "The Mark Has Been Made",
        "youtubeId": "yUCTlqExGyo",
        "halo": "The Fragile",
        "start": 192
    },
    {
        "date": "06/28/23",
        "artist": "Nine Inch Nails",
        "name": "Last",
        "youtubeId": "DzkBmJGGwdA",
        "halo": "Broken",
        "start": 118
    },
    {
        "date": "06/29/23",
        "artist": "Nine Inch Nails",
        "name": "She's Gone Away",
        "youtubeId": "r2bL7DU21Wg",
        "halo": "Not The Actual Events",
        "start": 255
    },
    {
        "date": "06/30/23",
        "artist": "Nine Inch Nails",
        "name": "Love Is Not Enough",
        "youtubeId": "xxufoj-GuRk",
        "halo": "With Teeth",
        "start": 179
    },
    {
        "date": "07/01/23",
        "artist": "Nine Inch Nails",
        "name": "Just Breathe",
        "youtubeId": "AwxF4X8hPxc",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "07/02/23",
        "artist": "Nine Inch Nails",
        "name": "The Beauty Of Being Numb",
        "youtubeId": "5xgqHh-zgKo",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "07/03/23",
        "artist": "Nine Inch Nails",
        "name": "Beside You In Time",
        "youtubeId": "dO12h46EOyQ",
        "halo": "With Teeth",
        "start": 292
    },
    {
        "date": "07/04/23",
        "artist": "Nine Inch Nails",
        "name": "Not So Pretty Now",
        "youtubeId": "LWdn9QYN4ag",
        "halo": "NINJA 2009 Summer Tour EP",
        "start": 140
    },
    {
        "date": "07/05/23",
        "artist": "Nine Inch Nails",
        "name": "Complication",
        "youtubeId": "ls9Gyv_v-G8",
        "halo": "The Fragile",
        "start": 119
    },
    {
        "date": "07/06/23",
        "artist": "Nine Inch Nails",
        "name": "I'm Looking Forward To Joining You, Finally",
        "youtubeId": "MBff4D18Tck",
        "halo": "The Fragile",
        "start": 180
    },
    {
        "date": "07/07/23",
        "artist": "Nine Inch Nails",
        "name": "Burning Bright (Field on Fire)",
        "youtubeId": "gm4tn8znQE0",
        "halo": "Not The Actual Events",
        "start": 233
    },
    {
        "date": "07/08/23",
        "artist": "Nine Inch Nails",
        "name": "You Know Who You Are",
        "youtubeId": "c5lWhubbiDs",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "07/09/23",
        "artist": "Nine Inch Nails",
        "name": "34 Ghosts IV",
        "youtubeId": "XF_ceFugJjQ",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "07/10/23",
        "artist": "Nine Inch Nails",
        "name": "Corona Radiata",
        "youtubeId": "BrhzPhTSxfg",
        "halo": "The Slip",
        "start": 343
    },
    {
        "date": "07/11/23",
        "artist": "Nine Inch Nails",
        "name": "Satellite",
        "youtubeId": "Tqymo0sVUwE",
        "halo": "Hesitation Marks",
        "start": 200
    },
    {
        "date": "07/12/23",
        "artist": "Nine Inch Nails",
        "name": "The Greater Good",
        "youtubeId": "3UF_uE3Cb5E",
        "halo": "Year Zero",
        "start": 268
    },
    {
        "date": "07/13/23",
        "artist": "Nine Inch Nails",
        "name": "Hurt",
        "youtubeId": "Ty-bLdf8Bsw",
        "halo": "The Downward Spiral",
        "start": 282
    },
    {
        "date": "07/14/23",
        "artist": "Nine Inch Nails",
        "name": "Dead Souls",
        "youtubeId": "sDHqywS6un0",
        "halo": "Soundtrack NIN",
        "start": 246
    },
    {
        "date": "07/15/23",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole (Soil)",
        "youtubeId": "eTyRKSkcRRY",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "07/16/23",
        "artist": "Nine Inch Nails",
        "name": "Me, I'm Not (Olof Dreijer)",
        "youtubeId": "pnjLWz5hf0w",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "07/17/23",
        "artist": "Nine Inch Nails",
        "name": "With Teeth",
        "youtubeId": "c1uaGkwmDa0",
        "halo": "With Teeth",
        "start": 245
    },
    {
        "date": "07/18/23",
        "artist": "Nine Inch Nails",
        "name": "Wish",
        "youtubeId": "IU5R8EKsomA",
        "halo": "Broken",
        "start": 197
    },
    {
        "date": "07/19/23",
        "artist": "Nine Inch Nails",
        "name": "God Given",
        "youtubeId": "FjFGXnKG3Rs",
        "halo": "Year Zero",
        "start": 188
    },
    {
        "date": "07/20/23",
        "artist": "Nine Inch Nails",
        "name": "Home",
        "youtubeId": "XaSZ2myWIGM",
        "halo": "With Teeth",
        "start": 141
    },
    {
        "date": "07/21/23",
        "artist": "Nine Inch Nails",
        "name": "Copy of A",
        "youtubeId": "zirr0LcHdDw",
        "halo": "Hesitation Marks",
        "start": 251
    },
    {
        "date": "07/22/23",
        "artist": "Nine Inch Nails",
        "name": "30 Ghosts IV",
        "youtubeId": "gZcJ4ZtY6AU",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "07/23/23",
        "artist": "Nine Inch Nails",
        "name": "Sanctified",
        "youtubeId": "pIyTpWyeYy0",
        "halo": "Pretty Hate Machine",
        "start": 210
    },
    {
        "date": "07/24/23",
        "artist": "Nine Inch Nails",
        "name": "Me, I'm Not",
        "youtubeId": "QW8QtHxbbzI",
        "halo": "Year Zero",
        "start": 256
    },
    {
        "date": "07/25/23",
        "artist": "Nine Inch Nails",
        "name": "The Four of Us Are Dying",
        "youtubeId": "_aaqlfVLT1Y",
        "halo": "The Slip",
        "start": 208
    },
    {
        "date": "07/26/23",
        "artist": "Nine Inch Nails",
        "name": "All The Love In The World",
        "youtubeId": "TkUrZvbh9bU",
        "halo": "With Teeth",
        "start": 189
    },
    {
        "date": "07/27/23",
        "artist": "Nine Inch Nails",
        "name": "We're In This Together",
        "youtubeId": "6naYkchULZ4",
        "halo": "The Fragile",
        "start": 397
    },
    {
        "date": "07/28/23",
        "artist": "Nine Inch Nails",
        "name": "Ringfinger",
        "youtubeId": "Xbp0qm-x1v8",
        "halo": "Pretty Hate Machine",
        "start": 257
    },
    {
        "date": "07/29/23",
        "artist": "Nine Inch Nails",
        "name": "22 Ghosts III",
        "youtubeId": "O7HJSXYzyaw",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "07/30/23",
        "artist": "Nine Inch Nails",
        "name": "Starfuckers, Inc. (Version) - Dave Ogilvie",
        "youtubeId": "_ON_N_82OWE",
        "halo": "Things Falling Apart",
        "start": 0
    },
    {
        "date": "07/31/23",
        "artist": "Nine Inch Nails",
        "name": "And All That Could Have Been",
        "youtubeId": "2U0flA_Yp64",
        "halo": "Still",
        "start": 265
    },
    {
        "date": "08/01/23",
        "artist": "Nine Inch Nails",
        "name": "Branches/Bones",
        "youtubeId": "I0KOy6H-s6w",
        "halo": "Not The Actual Events",
        "start": 54
    },
    {
        "date": "08/02/23",
        "artist": "Nine Inch Nails",
        "name": "The Becoming",
        "youtubeId": "QWDsyvIfbak",
        "halo": "The Downward Spiral",
        "start": 206
    },
    {
        "date": "08/03/23",
        "artist": "Nine Inch Nails",
        "name": "God Break Down The Door",
        "youtubeId": "5gyOKTOcCyM",
        "halo": "Bad Witch",
        "start": 213
    },
    {
        "date": "08/04/23",
        "artist": "Nine Inch Nails",
        "name": "La Mer",
        "youtubeId": "dcIOInVS7jo",
        "halo": "The Fragile",
        "start": 215
    },
    {
        "date": "08/05/23",
        "artist": "Nine Inch Nails",
        "name": "Ruiner (Demo)",
        "youtubeId": "8HvX2d_ne9Q",
        "halo": "The Downward Spiral (Deluxe)",
        "start": 0
    },
    {
        "date": "08/06/23",
        "artist": "Nine Inch Nails",
        "name": "Gave Up (remixed by Coil, Danny Hyde)",
        "youtubeId": "IBjlDO6cH5Q",
        "halo": "Fixed",
        "start": 0
    },
    {
        "date": "08/07/23",
        "artist": "Nine Inch Nails",
        "name": "The Big Come Down",
        "youtubeId": "9gg2p7_PnTQ",
        "halo": "The Fragile",
        "start": 169
    },
    {
        "date": "08/08/23",
        "artist": "Nine Inch Nails",
        "name": "Down In It",
        "youtubeId": "uXdIc8TZKcA",
        "halo": "Pretty Hate Machine",
        "start": 219
    },
    {
        "date": "08/09/23",
        "artist": "Nine Inch Nails",
        "name": "The Warning",
        "youtubeId": "EqAD4iq87Os",
        "halo": "Year Zero",
        "start": 159
    },
    {
        "date": "08/10/23",
        "artist": "Nine Inch Nails",
        "name": "Burn",
        "youtubeId": "UePiNA--ook",
        "halo": "Soundtrack NIN",
        "start": 220
    },
    {
        "date": "08/11/23",
        "artist": "Nine Inch Nails",
        "name": "Sin",
        "youtubeId": "5bwE5IU4pzA",
        "halo": "Pretty Hate Machine",
        "start": 161
    },
    {
        "date": "08/12/23",
        "artist": "Nine Inch Nails",
        "name": "Letting Go While Holding On",
        "youtubeId": "dp58R7BEhrw",
        "halo": "Ghosts V: Together",
        "start": 0
    },
    {
        "date": "08/13/23",
        "artist": "Nine Inch Nails",
        "name": "Wish (remixed by J. G. Thirlwell)",
        "youtubeId": "PZoEMqQWTU4",
        "halo": "Fixed",
        "start": 0
    },
    {
        "date": "08/14/23",
        "artist": "Nine Inch Nails",
        "name": "The Day The World Went Away",
        "youtubeId": "TfKTgx15jag",
        "halo": "The Fragile",
        "start": 191
    },
    {
        "date": "08/15/23",
        "artist": "Nine Inch Nails",
        "name": "Pilgrimage",
        "youtubeId": "xJUJ-qNdsf8",
        "halo": "The Fragile",
        "start": 198
    },
    {
        "date": "08/16/23",
        "artist": "Nine Inch Nails",
        "name": "Capital G",
        "youtubeId": "4Qr2jh1HlWU",
        "halo": "Year Zero",
        "start": 156
    },
    {
        "date": "08/17/23",
        "artist": "Nine Inch Nails",
        "name": "A Warm Place",
        "youtubeId": "Yld7Fs-VfRE",
        "halo": "The Downward Spiral",
        "start": 150
    },
    {
        "date": "08/18/23",
        "artist": "Nine Inch Nails",
        "name": "The Great Below",
        "youtubeId": "HCORXBfRcNo",
        "halo": "The Fragile",
        "start": 268
    },
    {
        "date": "08/19/23",
        "artist": "Nine Inch Nails",
        "name": "Apart",
        "youtubeId": "v2T_RtzdgLY",
        "halo": "Ghosts V: Together",
        "start": 0
    },
    {
        "date": "08/20/23",
        "artist": "Nine Inch Nails",
        "name": "While I'm Still Here (Breyer P-Orridge 'Howler' Remix)",
        "youtubeId": "0Eo4SX57Ql0",
        "halo": "Hesitation Marks (Deluxe)",
        "start": 0
    },
    {
        "date": "08/21/23",
        "artist": "Nine Inch Nails",
        "name": "Getting Smaller",
        "youtubeId": "oZT8hU3X15U",
        "halo": "With Teeth",
        "start": 173
    },
    {
        "date": "08/22/23",
        "artist": "Nine Inch Nails",
        "name": "Letting You",
        "youtubeId": "FGhnRVqrTWs",
        "halo": "The Slip",
        "start": 208
    },
    {
        "date": "08/23/23",
        "artist": "Nine Inch Nails",
        "name": "Even Deeper",
        "youtubeId": "hwz86XHCMdU",
        "halo": "The Fragile",
        "start": 243
    },
    {
        "date": "08/24/23",
        "artist": "Nine Inch Nails",
        "name": "I Do Not Want This",
        "youtubeId": "kb5_hIuEk8g",
        "halo": "The Downward Spiral",
        "start": 220
    },
    {
        "date": "08/25/23",
        "artist": "Nine Inch Nails",
        "name": "The Only Time",
        "youtubeId": "EWc00wZWG_k",
        "halo": "Pretty Hate Machine",
        "start": 190
    },
    {
        "date": "08/26/23",
        "artist": "Nine Inch Nails",
        "name": "Copy of A (Simian Mobile Disco Remix)",
        "youtubeId": "xsuIdNR-PUI",
        "halo": "Remix 2014 EP",
        "start": 664
    },
    {
        "date": "08/27/23",
        "artist": "Nine Inch Nails",
        "name": "No You Don't (Version)",
        "youtubeId": "Y_7vJHs3HmM",
        "halo": "The Fragile Hillebrandt Mixes",
        "start": 0
    },
    {
        "date": "08/28/23",
        "artist": "Nine Inch Nails",
        "name": "Non-Entity",
        "youtubeId": "Bg76mSSzErs",
        "halo": "NINJA 2009 Summer Tour EP",
        "start": 181
    },
    {
        "date": "08/29/23",
        "artist": "Nine Inch Nails",
        "name": "Demon Seed",
        "youtubeId": "eICUTG7Oyik",
        "halo": "The Slip",
        "start": 219
    },
    {
        "date": "08/30/23",
        "artist": "Nine Inch Nails",
        "name": "Piggy",
        "youtubeId": "SSKp94wMEu0",
        "halo": "The Downward Spiral",
        "start": 209
    },
    {
        "date": "08/31/23",
        "artist": "Nine Inch Nails",
        "name": "I'm Not From This World",
        "youtubeId": "9fjbcSUSt9w",
        "halo": "Bad Witch",
        "start": 340
    },
    {
        "date": "09/01/23",
        "artist": "Nine Inch Nails",
        "name": "Over and Out",
        "youtubeId": "h-XlN3N2fis",
        "halo": "Bad Witch",
        "start": 290
    },
    {
        "date": "09/02/23",
        "artist": "Nine Inch Nails",
        "name": "Capital G (Epworth Phones)",
        "youtubeId": "mU7Oi7hTP7U",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "09/03/23",
        "artist": "Nine Inch Nails",
        "name": "Underneath The Skin",
        "youtubeId": "UGel3dOOThU",
        "halo": "March of the Pigs",
        "start": 0
    },
    {
        "date": "09/04/23",
        "artist": "Nine Inch Nails",
        "name": "This Isn't The Place",
        "youtubeId": "ws_wqrTifcg",
        "halo": "Add Violence",
        "start": 246
    },
    {
        "date": "09/05/23",
        "artist": "Nine Inch Nails",
        "name": "(+Appendage)",
        "youtubeId": "zCD_2soPhVc",
        "halo": "The Fragile",
        "start": 117
    },
    {
        "date": "09/06/23",
        "artist": "Nine Inch Nails",
        "name": "Eraser",
        "youtubeId": "Ep-E2jZScwI",
        "halo": "The Downward Spiral",
        "start": 135
    },
    {
        "date": "09/07/23",
        "artist": "Nine Inch Nails",
        "name": "999,999",
        "youtubeId": "JOUBNVtg200",
        "halo": "The Slip",
        "start": 60
    },
    {
        "date": "09/08/23",
        "artist": "Nine Inch Nails",
        "name": "Reptile",
        "youtubeId": "4d64sKlm-Wo",
        "halo": "The Downward Spiral",
        "start": 309
    },
    {
        "date": "09/09/23",
        "artist": "Nine Inch Nails",
        "name": "A Really Bad Night",
        "youtubeId": "BPFPpQHyZQE",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "09/10/23",
        "artist": "Nine Inch Nails",
        "name": "6 Ghosts I",
        "youtubeId": "y-1SsMn67m8",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "09/11/23",
        "artist": "Nine Inch Nails",
        "name": "Dear World,",
        "youtubeId": "9bx-xlof6q4",
        "halo": "Not The Actual Events",
        "start": 130
    },
    {
        "date": "09/12/23",
        "artist": "Nine Inch Nails",
        "name": "The Way Out Is Through",
        "youtubeId": "_WVA92lnty0",
        "halo": "The Fragile",
        "start": 88
    },
    {
        "date": "09/13/23",
        "artist": "Nine Inch Nails",
        "name": "Echoplex",
        "youtubeId": "_c7ixDZj9DA",
        "halo": "The Slip",
        "start": 153
    },
    {
        "date": "09/14/23",
        "artist": "Nine Inch Nails",
        "name": "Closer",
        "youtubeId": "9-5IIx4R6E8",
        "halo": "The Downward Spiral",
        "start": 251
    },
    {
        "date": "09/15/23",
        "artist": "Nine Inch Nails",
        "name": "No, You Don't",
        "youtubeId": "IhCVl8Z63D8",
        "halo": "The Fragile",
        "start": 104
    },
    {
        "date": "09/16/23",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds (Photek Dub Mix)",
        "youtubeId": "oAWecyQq9M0",
        "halo": "The Hand That Feeds",
        "start": 0
    },
    {
        "date": "09/17/23",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds (Ruff Mix)",
        "youtubeId": "S3LAqIm6u9k",
        "halo": "The Hand That Feeds",
        "start": 0
    },
    {
        "date": "09/18/23",
        "artist": "Nine Inch Nails",
        "name": "Ruiner",
        "youtubeId": "SO4p9DeaCkw",
        "halo": "The Downward Spiral",
        "start": 204
    },
    {
        "date": "09/19/23",
        "artist": "Nine Inch Nails",
        "name": "Another Version Of The Truth",
        "youtubeId": "dJae6JW7LLQ",
        "halo": "Year Zero",
        "start": 180
    },
    {
        "date": "09/20/23",
        "artist": "Nine Inch Nails",
        "name": "March Of The Pigs",
        "youtubeId": "ojk-YRMXSdc",
        "halo": "The Downward Spiral",
        "start": 126
    },
    {
        "date": "09/21/23",
        "artist": "Nine Inch Nails",
        "name": "Everything",
        "youtubeId": "2l0swKptAqc",
        "halo": "Hesitation Marks",
        "start": 100
    },
    {
        "date": "09/22/23",
        "artist": "Nine Inch Nails",
        "name": "Ahead of Ourselves",
        "youtubeId": "4Ab1O-i4ep4",
        "halo": "Bad Witch",
        "start": 73
    },
    {
        "date": "09/23/23",
        "artist": "Nine Inch Nails",
        "name": "Survivalism (Survivalism_Tardusted) (Saul Williams)",
        "youtubeId": "zfcazPTzKjc",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "09/24/23",
        "artist": "Nine Inch Nails",
        "name": "23 Ghosts III",
        "youtubeId": "f-YOlDmsBEs",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "09/25/23",
        "artist": "Nine Inch Nails",
        "name": "My Violent Heart",
        "youtubeId": "hqRk5kXROS4",
        "halo": "Year Zero",
        "start": 179
    },
    {
        "date": "09/26/23",
        "artist": "Nine Inch Nails",
        "name": "Head Down",
        "youtubeId": "pq_wwGSFJlI",
        "halo": "The Slip",
        "start": 175
    },
    {
        "date": "09/27/23",
        "artist": "Nine Inch Nails",
        "name": "Meet Your Master",
        "youtubeId": "P9MqBx3nc0M",
        "halo": "Year Zero",
        "start": 180
    },
    {
        "date": "09/28/23",
        "artist": "Nine Inch Nails",
        "name": "The Line Begins To Blur",
        "youtubeId": "lmygz88y348",
        "halo": "With Teeth",
        "start": 183
    },
    {
        "date": "09/29/23",
        "artist": "Nine Inch Nails",
        "name": "The Good Soldier",
        "youtubeId": "9lzJ4LvYOFk",
        "halo": "Year Zero",
        "start": 158
    },
    {
        "date": "09/30/23",
        "artist": "Nine Inch Nails",
        "name": "Self Destruction, Final",
        "youtubeId": "MsuWEKtxyDY",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "10/01/23",
        "artist": "Nine Inch Nails",
        "name": "16 Ghosts II",
        "youtubeId": "AXT-ZpP2lTI",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "10/02/23",
        "artist": "Nine Inch Nails",
        "name": "Physical (You're So)",
        "youtubeId": "nOvX75ck1_E",
        "halo": "Broken",
        "start": 234
    },
    {
        "date": "10/03/23",
        "artist": "Nine Inch Nails",
        "name": "Came Back Haunted",
        "youtubeId": "h6iKmf6nbLk",
        "halo": "Hesitation Marks",
        "start": 174
    },
    {
        "date": "10/04/23",
        "artist": "Nine Inch Nails",
        "name": "10 Miles High",
        "youtubeId": "hmPQDbbgA1Y",
        "halo": "The Fragile",
        "start": 220
    },
    {
        "date": "10/05/23",
        "artist": "Nine Inch Nails",
        "name": "Vessel",
        "youtubeId": "pUeLrzfmtUk",
        "halo": "Year Zero",
        "start": 250
    },
    {
        "date": "10/06/23",
        "artist": "Nine Inch Nails",
        "name": "Sunspots",
        "youtubeId": "dOJpXttqA3c",
        "halo": "With Teeth",
        "start": 160
    },
    {
        "date": "10/07/23",
        "artist": "Nine Inch Nails",
        "name": "21 Ghosts III",
        "youtubeId": "HdyRMPIKgWU",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "10/08/23",
        "artist": "Nine Inch Nails",
        "name": "Vessel (Bill Laswell)",
        "youtubeId": "3URrfSNyl50",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "10/09/23",
        "artist": "Nine Inch Nails",
        "name": "Kinda I Want To",
        "youtubeId": "1UaqcWBtT-o",
        "halo": "Pretty Hate Machine",
        "start": 210
    },
    {
        "date": "10/10/23",
        "artist": "Nine Inch Nails",
        "name": "The Wretched",
        "youtubeId": "ANjQ8wI-W7g",
        "halo": "The Fragile",
        "start": 219
    },
    {
        "date": "10/11/23",
        "artist": "Nine Inch Nails",
        "name": "Black Noise",
        "youtubeId": "lvHL86ZSFY4",
        "halo": "Hesitation Marks",
        "start": 60
    },
    {
        "date": "10/12/23",
        "artist": "Nine Inch Nails",
        "name": "Where Is Everybody?",
        "youtubeId": "uQJq-WJXUAU",
        "halo": "The Fragile",
        "start": 226
    },
    {
        "date": "10/13/23",
        "artist": "Nine Inch Nails",
        "name": "Shit Mirror",
        "youtubeId": "yeqjz5mXrLQ",
        "halo": "Bad Witch",
        "start": 160
    },
    {
        "date": "10/14/23",
        "artist": "Nine Inch Nails",
        "name": "Terrible Lie (Sympathetic)",
        "youtubeId": "ZTPByfA5new",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "10/15/23",
        "artist": "Nine Inch Nails",
        "name": "17 Ghosts II",
        "youtubeId": "A6TBJEDYU2E",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "10/16/23",
        "artist": "Nine Inch Nails",
        "name": "All Time Low",
        "youtubeId": "--kYVgXjoEk",
        "halo": "Hesitation Marks",
        "start": 146
    },
    {
        "date": "10/17/23",
        "artist": "Nine Inch Nails",
        "name": "Zero-Sum",
        "youtubeId": "AMqG4uWmqzs",
        "halo": "Year Zero",
        "start": 294
    },
    {
        "date": "10/18/23",
        "artist": "Nine Inch Nails",
        "name": "Into The Void",
        "youtubeId": "Q8LEheSUTGE",
        "halo": "The Fragile",
        "start": 234
    },
    {
        "date": "10/19/23",
        "artist": "Nine Inch Nails",
        "name": "I Would for You",
        "youtubeId": "lJQs-ShfsMc",
        "halo": "Hesitation Marks",
        "start": 246
    },
    {
        "date": "10/20/23",
        "artist": "Nine Inch Nails",
        "name": "That's What I Get",
        "youtubeId": "NU4wlbT4nBw",
        "halo": "Pretty Hate Machine",
        "start": 186
    },
    {
        "date": "10/21/23",
        "artist": "Nine Inch Nails",
        "name": "Closer (Precursor)",
        "youtubeId": "qk34MnL1Z3k",
        "halo": "Closer To God",
        "start": 0
    },
    {
        "date": "10/22/23",
        "artist": "Nine Inch Nails",
        "name": "24 Ghosts III",
        "youtubeId": "B8diSUGiklM",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "10/23/23",
        "artist": "Nine Inch Nails",
        "name": "Discipline",
        "youtubeId": "wHxOiAz4NF8",
        "halo": "The Slip",
        "start": 166
    },
    {
        "date": "10/24/23",
        "artist": "Nine Inch Nails",
        "name": "Help Me I Am In Hell",
        "youtubeId": "G2BAAYsqTww",
        "halo": "Broken",
        "start": 90
    },
    {
        "date": "10/25/23",
        "artist": "Nine Inch Nails",
        "name": "Just Like You Imagined",
        "youtubeId": "OqWM-tXtoBU",
        "halo": "The Fragile",
        "start": 86
    },
    {
        "date": "10/26/23",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug",
        "youtubeId": "pa5YJRcfCfM",
        "halo": "Soundtrack NIN",
        "start": 195
    },
    {
        "date": "10/27/23",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds",
        "youtubeId": "qn7HvnMJZd4",
        "halo": "With Teeth",
        "start": 104
    },
    {
        "date": "10/28/23",
        "artist": "Nine Inch Nails",
        "name": "1 Ghosts I",
        "youtubeId": "JA5rc4M-YrA",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "10/29/23",
        "artist": "Nine Inch Nails",
        "name": "Throw This Away",
        "youtubeId": "Mg_VMavnfmE",
        "halo": "Fixed",
        "start": 0
    },
    {
        "date": "10/30/23",
        "artist": "Nine Inch Nails",
        "name": "Underneath It All",
        "youtubeId": "pzXeJ762BBc",
        "halo": "The Fragile",
        "start": 148
    },
    {
        "date": "10/31/23",
        "artist": "Nine Inch Nails",
        "name": "The Eater of Dreams",
        "youtubeId": "rxtAYx6auC4",
        "halo": "Hesitation Marks",
        "start": 22
    },
    {
        "date": "11/01/23",
        "artist": "Nine Inch Nails",
        "name": "Ripe (With Decay)",
        "youtubeId": "HkrUlSKRQRo",
        "halo": "The Fragile",
        "start": 316
    },
    {
        "date": "11/02/23",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole",
        "youtubeId": "ghIRu6UvQPc",
        "halo": "Pretty Hate Machine",
        "start": 110
    },
    {
        "date": "11/03/23",
        "artist": "Nine Inch Nails",
        "name": "Right Where It Belongs v2",
        "youtubeId": "vxrf4ZzzrA8",
        "halo": "With Teeth",
        "start": 152
    },
    {
        "date": "11/04/23",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole (Opal)",
        "youtubeId": "uLKJzDixrvw",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "11/05/23",
        "artist": "Nine Inch Nails",
        "name": "38 Ghosts",
        "youtubeId": "R_sn9CwApbQ",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "11/06/23",
        "artist": "Nine Inch Nails",
        "name": "Closer (Internal)",
        "youtubeId": "Im_U7Ibg6Ao",
        "halo": "Closer To God",
        "start": 0
    },
    {
        "date": "11/07/23",
        "artist": "Nine Inch Nails",
        "name": "Zero-Sum (Stephen Morris & Gillian Gilbert)",
        "youtubeId": "jTocT97M3i8",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "11/08/23",
        "artist": "Nine Inch Nails",
        "name": "Running (Cold Cave Remix)",
        "youtubeId": "xsuIdNR-PUI",
        "halo": "Remix 2014 EP",
        "start": 404
    },
    {
        "date": "11/09/23",
        "artist": "Nine Inch Nails",
        "name": "11 Ghosts II",
        "youtubeId": "TvtYSJnm7Lc",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "11/10/23",
        "artist": "Nine Inch Nails",
        "name": "Hurt (Quiet)",
        "youtubeId": "N-vpkja2fQ8",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "11/11/23",
        "artist": "Nine Inch Nails",
        "name": "Turn This Off Please",
        "youtubeId": "nGIhlhWVmac",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "11/12/23",
        "artist": "Nine Inch Nails",
        "name": "Right Behind You",
        "youtubeId": "tZe9iYpw-K0",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "11/13/23",
        "artist": "Nine Inch Nails",
        "name": "Slate (Purest Feeling)",
        "youtubeId": "1Mif3Tn2OoY",
        "halo": "Purest Feeling",
        "start": 0
    },
    {
        "date": "11/14/23",
        "artist": "Nine Inch Nails",
        "name": "Sanctified (Purest Feeling)",
        "youtubeId": "29tiwCwRcjc",
        "halo": "Purest Feeling",
        "start": 0
    },
    {
        "date": "11/15/23",
        "artist": "Nine Inch Nails",
        "name": "Down In It (Demo)",
        "youtubeId": "cRIi4Pf8gxw",
        "halo": "Down In It",
        "start": 0
    },
    {
        "date": "11/16/23",
        "artist": "Nine Inch Nails",
        "name": "Ruiner (Version)",
        "youtubeId": "JzIaXusWT1w",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "11/17/23",
        "artist": "Nine Inch Nails",
        "name": "Sin (Dub)",
        "youtubeId": "loguBY4E2uY",
        "halo": "Sin",
        "start": 0
    },
    {
        "date": "11/18/23",
        "artist": "Nine Inch Nails",
        "name": "Hurt (Dissonance Tour)",
        "youtubeId": "fhhEHuChFck",
        "halo": "Unpublished Remixes",
        "start": 11
    },
    {
        "date": "11/19/23",
        "artist": "Nine Inch Nails",
        "name": "Around Every Corner",
        "youtubeId": "93uaW2gJBxA",
        "halo": "Ghosts VI: Locusts",
        "start": 0
    },
    {
        "date": "11/20/23",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug (Aphrodite)",
        "youtubeId": "hPvHlGQsdCw",
        "halo": "The Perfect Drug Versions",
        "start": 0
    },
    {
        "date": "11/21/23",
        "artist": "Nine Inch Nails",
        "name": "Everything (Autolux Remix)",
        "youtubeId": "xsuIdNR-PUI",
        "halo": "Remix 2014 EP",
        "start": 1166
    },
    {
        "date": "11/22/23",
        "artist": "Nine Inch Nails",
        "name": "Self Destruction, Part Three",
        "youtubeId": "MZRD_GuQsVg",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "11/23/23",
        "artist": "Nine Inch Nails",
        "name": "12 Ghosts II",
        "youtubeId": "jGJhAheCqv0",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "11/24/23",
        "artist": "Nine Inch Nails",
        "name": "Sin (Short)",
        "youtubeId": "bGG2Gy7hh7U",
        "halo": "Sin",
        "start": 0
    },
    {
        "date": "11/25/23",
        "artist": "Nine Inch Nails",
        "name": "God Given (Stephen Morris & Gillian Gilbert)",
        "youtubeId": "uXETEJXw4fY",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "11/26/23",
        "artist": "Nine Inch Nails",
        "name": "8 Ghosts I",
        "youtubeId": "mB-epcA0fMM",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "11/27/23",
        "artist": "Nine Inch Nails",
        "name": "Liar (Reptile Demo)",
        "youtubeId": "ww3-m9n4KHs",
        "halo": "The Downward Spiral (Deluxe)",
        "start": 0
    },
    {
        "date": "11/28/23",
        "artist": "Nine Inch Nails",
        "name": "Capital G (Ladytron)",
        "youtubeId": "OMBqjxOCviA",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "11/29/23",
        "artist": "Nine Inch Nails",
        "name": "Terrible Lie (Empathetic)",
        "youtubeId": "jKGySEP0XEU",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "11/30/23",
        "artist": "Nine Inch Nails",
        "name": "Another Version Of The Truth (Kronos & Enrique Gonzalez M\u00fcller)",
        "youtubeId": "NbBE6vGX5-E",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "12/01/23",
        "artist": "Nine Inch Nails",
        "name": "Down In It (Shred)",
        "youtubeId": "cBy4WUAf7UQ",
        "halo": "Down In It",
        "start": 0
    },
    {
        "date": "12/02/23",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds (DFA Mix)",
        "youtubeId": "g5eIE9xpBH4",
        "halo": "Every Day Is Exactly The Same",
        "start": 0
    },
    {
        "date": "12/03/23",
        "artist": "Nine Inch Nails",
        "name": "The Art Of Self Destruction, Part One",
        "youtubeId": "-li7w_62geg",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "12/04/23",
        "artist": "Nine Inch Nails",
        "name": "The Day The World Went Away (Porter Ricks)",
        "youtubeId": "SGCpg-ri7qo",
        "halo": "The Day The World Went Away",
        "start": 0
    },
    {
        "date": "12/05/23",
        "artist": "Nine Inch Nails",
        "name": "Starfuckers, Inc. (Version) - Charlie Clouser",
        "youtubeId": "K2VpPlupDus",
        "halo": "Things Falling Apart",
        "start": 0
    },
    {
        "date": "12/06/23",
        "artist": "Nine Inch Nails",
        "name": "29 Ghosts IV",
        "youtubeId": "DlRkSjJ-wvI",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/07/23",
        "artist": "Nine Inch Nails",
        "name": "10 Ghosts II",
        "youtubeId": "HnY3aePtg9Q",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/08/23",
        "artist": "Nine Inch Nails",
        "name": "Heresy (Version)",
        "youtubeId": "higPeuBiI-8",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "12/09/23",
        "artist": "Nine Inch Nails",
        "name": "13 Ghosts II",
        "youtubeId": "s_BNUwxAAGQ",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/10/23",
        "artist": "Nine Inch Nails",
        "name": "5 Ghosts I",
        "youtubeId": "srJYQESO34g",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/11/23",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole (Copper)",
        "youtubeId": "QnXSk_Hu5k8",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "12/12/23",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug (Spacetime Continuum)",
        "youtubeId": "2t01jpcBmDY",
        "halo": "The Perfect Drug Versions",
        "start": 0
    },
    {
        "date": "12/13/23",
        "artist": "Nine Inch Nails",
        "name": "At The Heart Of It All",
        "youtubeId": "mXOgc2p5HyQ",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "12/14/23",
        "artist": "Nine Inch Nails",
        "name": "Only (EL-P Mix)",
        "youtubeId": "2yW31N-F4Yg",
        "halo": "Every Day Is Exactly The Same",
        "start": 0
    },
    {
        "date": "12/15/23",
        "artist": "Nine Inch Nails",
        "name": "25 Ghosts III",
        "youtubeId": "oTYb5XwJ7Ss",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/16/23",
        "artist": "Nine Inch Nails",
        "name": "Taken",
        "youtubeId": "9UkgiC5Xmbc",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "12/17/23",
        "artist": "Nine Inch Nails",
        "name": "All Time Low (Todd Rundgren Remix)",
        "youtubeId": "CjBWf85AKx8",
        "halo": "Hesitation Marks (Deluxe)",
        "start": 0
    },
    {
        "date": "12/18/23",
        "artist": "Nine Inch Nails",
        "name": "4 Ghosts I",
        "youtubeId": "M72X-mXd-kA",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/19/23",
        "artist": "Nine Inch Nails",
        "name": "Happiness In Slavery (remixed by Trent Reznor, Vrenna, P.K.)",
        "youtubeId": "Jl3dx22ooho",
        "halo": "Fixed",
        "start": 0
    },
    {
        "date": "12/20/23",
        "artist": "Nine Inch Nails",
        "name": "Feeders",
        "youtubeId": "tBO-wOzcSLE",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "12/21/23",
        "artist": "Nine Inch Nails",
        "name": "Heresy (Demo)",
        "youtubeId": "in8TMsz6cts",
        "halo": "The Downward Spiral (Deluxe)",
        "start": 0
    },
    {
        "date": "12/22/23",
        "artist": "Nine Inch Nails",
        "name": "2 Ghosts I",
        "youtubeId": "T6R8GCX_wSs",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/23/23",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug (Meat Beat Manifesto)",
        "youtubeId": "uKxu3mX6Rps",
        "halo": "The Perfect Drug Versions",
        "start": 0
    },
    {
        "date": "12/24/23",
        "artist": "Nine Inch Nails",
        "name": "Statellite (Hot Chip Remix)",
        "youtubeId": "xsuIdNR-PUI",
        "halo": "Remix 2014 EP",
        "start": 0
    },
    {
        "date": "12/25/23",
        "artist": "Nine Inch Nails",
        "name": "That's What I Get (Purest Feeling)",
        "youtubeId": "HqD00xc90eQ",
        "halo": "Purest Feeling",
        "start": 0
    },
    {
        "date": "12/26/23",
        "artist": "Nine Inch Nails",
        "name": "My Violent Heart (Pirate Robot Midget)",
        "youtubeId": "crAxdBfJWCE",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "12/27/23",
        "artist": "Nine Inch Nails",
        "name": "37 Ghosts",
        "youtubeId": "zxEC4hGdSWI",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "12/28/23",
        "artist": "Nine Inch Nails",
        "name": "The Collector",
        "youtubeId": "LWx8pOMpDJQ",
        "halo": "With Teeth",
        "start": 71
    },
    {
        "date": "12/29/23",
        "artist": "Nine Inch Nails",
        "name": "The Background World",
        "youtubeId": "nDIjTaPt9co",
        "halo": "Add Violence",
        "start": 316
    },
    {
        "date": "12/30/23",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole (Soil)",
        "youtubeId": "eTyRKSkcRRY",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "12/31/23",
        "artist": "Nine Inch Nails",
        "name": "Sin (Dub)",
        "youtubeId": "loguBY4E2uY",
        "halo": "Sin",
        "start": 0
    },
    {
        "date": "01/01/24",
        "artist": "Nine Inch Nails",
        "name": "The Greater Good",
        "youtubeId": "3UF_uE3Cb5E",
        "halo": "Year Zero",
        "start": 130
    },
    {
        "date": "01/02/24",
        "artist": "Nine Inch Nails",
        "name": "La Mer",
        "youtubeId": "dcIOInVS7jo",
        "halo": "The Fragile",
        "start": 223
    },
    {
        "date": "01/03/24",
        "artist": "Nine Inch Nails",
        "name": "Ringfinger",
        "youtubeId": "Xbp0qm-x1v8",
        "halo": "Pretty Hate Machine",
        "start": 308
    },
    {
        "date": "01/04/24",
        "artist": "Nine Inch Nails",
        "name": "All The Love In The World",
        "youtubeId": "TkUrZvbh9bU",
        "halo": "With Teeth",
        "start": 291
    },
    {
        "date": "01/05/24",
        "artist": "Nine Inch Nails",
        "name": "The Wretched",
        "youtubeId": "ANjQ8wI-W7g",
        "halo": "The Fragile",
        "start": 254
    },
    {
        "date": "01/06/24",
        "artist": "Nine Inch Nails",
        "name": "12 Ghosts II",
        "youtubeId": "jGJhAheCqv0",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "01/07/24",
        "artist": "Nine Inch Nails",
        "name": "God Given (Stephen Morris & Gillian Gilbert)",
        "youtubeId": "uXETEJXw4fY",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "01/08/24",
        "artist": "Nine Inch Nails",
        "name": "The Fragile",
        "youtubeId": "BO-iKVlPz8I",
        "halo": "The Fragile",
        "start": 176
    },
    {
        "date": "01/09/24",
        "artist": "Nine Inch Nails",
        "name": "Branches/Bones",
        "youtubeId": "I0KOy6H-s6w",
        "halo": "Not The Actual Events",
        "start": 81
    },
    {
        "date": "01/10/24",
        "artist": "Nine Inch Nails",
        "name": "Gave Up",
        "youtubeId": "EDgt-hw8A40",
        "halo": "Broken",
        "start": 8
    },
    {
        "date": "01/11/24",
        "artist": "Nine Inch Nails",
        "name": "Capital G",
        "youtubeId": "4Qr2jh1HlWU",
        "halo": "Year Zero",
        "start": 63
    },
    {
        "date": "01/12/24",
        "artist": "Nine Inch Nails",
        "name": "Disappointed",
        "youtubeId": "bOXXayxeSlY",
        "halo": "Hesitation Marks",
        "start": 100
    },
    {
        "date": "01/13/24",
        "artist": "Nine Inch Nails",
        "name": "Sin (Short)",
        "youtubeId": "bGG2Gy7hh7U",
        "halo": "Sin",
        "start": 0
    },
    {
        "date": "01/14/24",
        "artist": "Nine Inch Nails",
        "name": "Ruiner (Version)",
        "youtubeId": "JzIaXusWT1w",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "01/15/24",
        "artist": "Nine Inch Nails",
        "name": "Heresy",
        "youtubeId": "6i8zwVIFxQg",
        "halo": "The Downward Spiral",
        "start": 149
    },
    {
        "date": "01/16/24",
        "artist": "Nine Inch Nails",
        "name": "Head Down",
        "youtubeId": "pq_wwGSFJlI",
        "halo": "The Slip",
        "start": 248
    },
    {
        "date": "01/17/24",
        "artist": "Nine Inch Nails",
        "name": "Help Me I Am In Hell",
        "youtubeId": "G2BAAYsqTww",
        "halo": "Broken",
        "start": 20
    },
    {
        "date": "01/18/24",
        "artist": "Nine Inch Nails",
        "name": "Find My Way",
        "youtubeId": "kw49JbrO-rc",
        "halo": "Hesitation Marks",
        "start": 285
    },
    {
        "date": "01/19/24",
        "artist": "Nine Inch Nails",
        "name": "Every Day Is Exactly The Same",
        "youtubeId": "HEdzyFJLRbc",
        "halo": "With Teeth",
        "start": 179
    },
    {
        "date": "01/20/24",
        "artist": "Nine Inch Nails",
        "name": "29 Ghosts IV",
        "youtubeId": "DlRkSjJ-wvI",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "01/21/24",
        "artist": "Nine Inch Nails",
        "name": "Statellite (Hot Chip Remix)",
        "youtubeId": "xsuIdNR-PUI",
        "halo": "Remix 2014 EP",
        "start": 0
    },
    {
        "date": "01/22/24",
        "artist": "Nine Inch Nails",
        "name": "Burning Bright (Field on Fire)",
        "youtubeId": "gm4tn8znQE0",
        "halo": "Not The Actual Events",
        "start": 297
    },
    {
        "date": "01/23/24",
        "artist": "Nine Inch Nails",
        "name": "Everything",
        "youtubeId": "2l0swKptAqc",
        "halo": "Hesitation Marks",
        "start": 99
    },
    {
        "date": "01/24/24",
        "artist": "Nine Inch Nails",
        "name": "I'm Looking Forward To Joining You, Finally",
        "youtubeId": "MBff4D18Tck",
        "halo": "The Fragile",
        "start": 103
    },
    {
        "date": "01/25/24",
        "artist": "Nine Inch Nails",
        "name": "Closer",
        "youtubeId": "9-5IIx4R6E8",
        "halo": "The Downward Spiral",
        "start": 215
    },
    {
        "date": "01/26/24",
        "artist": "Nine Inch Nails",
        "name": "God Given",
        "youtubeId": "FjFGXnKG3Rs",
        "halo": "Year Zero",
        "start": 92
    },
    {
        "date": "01/27/24",
        "artist": "Nine Inch Nails",
        "name": "The Fragile (Deconstructed)",
        "youtubeId": "Nljr9ZxUBZE",
        "halo": "The Fragile Hillebrandt Mixes",
        "start": 10
    },
    {
        "date": "01/28/24",
        "artist": "Nine Inch Nails",
        "name": "5 Ghosts I",
        "youtubeId": "srJYQESO34g",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "01/29/24",
        "artist": "Nine Inch Nails",
        "name": "Deep",
        "youtubeId": "pEJOZITGpmI",
        "halo": "Soundtrack NIN",
        "start": 166
    },
    {
        "date": "01/30/24",
        "artist": "Nine Inch Nails",
        "name": "God Break Down The Door",
        "youtubeId": "5gyOKTOcCyM",
        "halo": "Bad Witch",
        "start": 125
    },
    {
        "date": "01/31/24",
        "artist": "Nine Inch Nails",
        "name": "Getting Smaller",
        "youtubeId": "oZT8hU3X15U",
        "halo": "With Teeth",
        "start": 16
    },
    {
        "date": "02/01/24",
        "artist": "Nine Inch Nails",
        "name": "The Big Come Down",
        "youtubeId": "9gg2p7_PnTQ",
        "halo": "The Fragile",
        "start": 185
    },
    {
        "date": "02/02/24",
        "artist": "Nine Inch Nails",
        "name": "Last",
        "youtubeId": "DzkBmJGGwdA",
        "halo": "Broken",
        "start": 135
    },
    {
        "date": "02/03/24",
        "artist": "Nine Inch Nails",
        "name": "25 Ghosts III",
        "youtubeId": "oTYb5XwJ7Ss",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "02/04/24",
        "artist": "Nine Inch Nails",
        "name": "At The Heart Of It All",
        "youtubeId": "mXOgc2p5HyQ",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "02/05/24",
        "artist": "Nine Inch Nails",
        "name": "Big Man With A Gun",
        "youtubeId": "Xe2rKjw8bww",
        "halo": "The Downward Spiral",
        "start": 13
    },
    {
        "date": "02/06/24",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug",
        "youtubeId": "pa5YJRcfCfM",
        "halo": "Soundtrack NIN",
        "start": 150
    },
    {
        "date": "02/07/24",
        "artist": "Nine Inch Nails",
        "name": "Get Down, Make Love",
        "youtubeId": "Sp_uMrVpgoU",
        "halo": "Sin",
        "start": 43
    },
    {
        "date": "02/08/24",
        "artist": "Nine Inch Nails",
        "name": "Home",
        "youtubeId": "XaSZ2myWIGM",
        "halo": "With Teeth",
        "start": 172
    },
    {
        "date": "02/09/24",
        "artist": "Nine Inch Nails",
        "name": "No, You Don't",
        "youtubeId": "IhCVl8Z63D8",
        "halo": "The Fragile",
        "start": 146
    },
    {
        "date": "02/10/24",
        "artist": "Nine Inch Nails",
        "name": "All Time Low (Todd Rundgren Remix)",
        "youtubeId": "CjBWf85AKx8",
        "halo": "Hesitation Marks (Deluxe)",
        "start": 0
    },
    {
        "date": "02/11/24",
        "artist": "Nine Inch Nails",
        "name": "Feeders",
        "youtubeId": "tBO-wOzcSLE",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "02/12/24",
        "artist": "Nine Inch Nails",
        "name": "We're In This Together",
        "youtubeId": "6naYkchULZ4",
        "halo": "The Fragile",
        "start": 247
    },
    {
        "date": "02/13/24",
        "artist": "Nine Inch Nails",
        "name": "The Beginning Of The End",
        "youtubeId": "Ce5vC_fBasE",
        "halo": "Year Zero",
        "start": 115
    },
    {
        "date": "02/14/24",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds",
        "youtubeId": "qn7HvnMJZd4",
        "halo": "With Teeth",
        "start": 8
    },
    {
        "date": "02/15/24",
        "artist": "Nine Inch Nails",
        "name": "Me, I'm Not",
        "youtubeId": "QW8QtHxbbzI",
        "halo": "Year Zero",
        "start": 279
    },
    {
        "date": "02/16/24",
        "artist": "Nine Inch Nails",
        "name": "The Becoming",
        "youtubeId": "QWDsyvIfbak",
        "halo": "The Downward Spiral",
        "start": 225
    },
    {
        "date": "02/17/24",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole (Copper)",
        "youtubeId": "QnXSk_Hu5k8",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "02/18/24",
        "artist": "Nine Inch Nails",
        "name": "In This Twilight (Fennesz)",
        "youtubeId": "8NzfJeaFgOA",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "02/19/24",
        "artist": "Nine Inch Nails",
        "name": "We're In This Together",
        "youtubeId": "6naYkchULZ4",
        "halo": "The Fragile",
        "start": 247
    },
    {
        "date": "02/20/24",
        "artist": "Nine Inch Nails",
        "name": "The Beginning Of The End",
        "youtubeId": "Ce5vC_fBasE",
        "halo": "Year Zero",
        "start": 115
    },
    {
        "date": "02/21/24",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds",
        "youtubeId": "qn7HvnMJZd4",
        "halo": "With Teeth",
        "start": 8
    },
    {
        "date": "02/22/24",
        "artist": "Nine Inch Nails",
        "name": "Me, I'm Not",
        "youtubeId": "QW8QtHxbbzI",
        "halo": "Year Zero",
        "start": 279
    },
    {
        "date": "02/23/24",
        "artist": "Nine Inch Nails",
        "name": "The Becoming",
        "youtubeId": "QWDsyvIfbak",
        "halo": "The Downward Spiral",
        "start": 225
    },
    {
        "date": "02/24/24",
        "artist": "Nine Inch Nails",
        "name": "Liar (Reptile Demo)",
        "youtubeId": "ww3-m9n4KHs",
        "halo": "The Downward Spiral (Deluxe)",
        "start": 0
    },
    {
        "date": "02/25/24",
        "artist": "Nine Inch Nails",
        "name": "Only (EL-P Mix)",
        "youtubeId": "2yW31N-F4Yg",
        "halo": "Every Day Is Exactly The Same",
        "start": 0
    },
    {
        "date": "02/26/24",
        "artist": "Nine Inch Nails",
        "name": "Where Is Everybody?",
        "youtubeId": "uQJq-WJXUAU",
        "halo": "The Fragile",
        "start": 85
    },
    {
        "date": "02/27/24",
        "artist": "Nine Inch Nails",
        "name": "A Warm Place",
        "youtubeId": "Yld7Fs-VfRE",
        "halo": "The Downward Spiral",
        "start": 43
    },
    {
        "date": "02/28/24",
        "artist": "Nine Inch Nails",
        "name": "The Idea of You",
        "youtubeId": "32E6B8P3J8M",
        "halo": "Not The Actual Events",
        "start": 133
    },
    {
        "date": "02/29/24",
        "artist": "Nine Inch Nails",
        "name": "With Teeth",
        "youtubeId": "c1uaGkwmDa0",
        "halo": "With Teeth",
        "start": 254
    },
    {
        "date": "03/01/24",
        "artist": "Nine Inch Nails",
        "name": "The Good Soldier",
        "youtubeId": "9lzJ4LvYOFk",
        "halo": "Year Zero",
        "start": 177
    },
    {
        "date": "03/02/24",
        "artist": "Nine Inch Nails",
        "name": "Another Version Of The Truth (Kronos & Enrique Gonzalez M\u00fcller)",
        "youtubeId": "NbBE6vGX5-E",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "03/03/24",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds (DFA Mix)",
        "youtubeId": "g5eIE9xpBH4",
        "halo": "Every Day Is Exactly The Same",
        "start": 0
    },
    {
        "date": "03/04/24",
        "artist": "Nine Inch Nails",
        "name": "I Would for You",
        "youtubeId": "lJQs-ShfsMc",
        "halo": "Hesitation Marks",
        "start": 258
    },
    {
        "date": "03/05/24",
        "artist": "Nine Inch Nails",
        "name": "Eraser",
        "youtubeId": "Ep-E2jZScwI",
        "halo": "The Downward Spiral",
        "start": 234
    },
    {
        "date": "03/06/24",
        "artist": "Nine Inch Nails",
        "name": "This Isn't The Place",
        "youtubeId": "ws_wqrTifcg",
        "halo": "Add Violence",
        "start": 249
    },
    {
        "date": "03/07/24",
        "artist": "Nine Inch Nails",
        "name": "The Great Below",
        "youtubeId": "HCORXBfRcNo",
        "halo": "The Fragile",
        "start": 295
    },
    {
        "date": "03/08/24",
        "artist": "Nine Inch Nails",
        "name": "The Warning",
        "youtubeId": "EqAD4iq87Os",
        "halo": "Year Zero",
        "start": 188
    },
    {
        "date": "03/09/24",
        "artist": "Nine Inch Nails",
        "name": "10 Ghosts II",
        "youtubeId": "HnY3aePtg9Q",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "03/10/24",
        "artist": "Nine Inch Nails",
        "name": "Taken",
        "youtubeId": "9UkgiC5Xmbc",
        "halo": "Deviations",
        "start": 0
    },
    {
        "date": "03/11/24",
        "artist": "Nine Inch Nails",
        "name": "Just Like You Imagined",
        "youtubeId": "OqWM-tXtoBU",
        "halo": "The Fragile",
        "start": 107
    },
    {
        "date": "03/12/24",
        "artist": "Nine Inch Nails",
        "name": "The Downward Spiral",
        "youtubeId": "f45yDddYePg",
        "halo": "The Downward Spiral",
        "start": 140
    },
    {
        "date": "03/13/24",
        "artist": "Nine Inch Nails",
        "name": "The Great Destroyer",
        "youtubeId": "erfTFe5tSWY",
        "halo": "Year Zero",
        "start": 190
    },
    {
        "date": "03/14/24",
        "artist": "Nine Inch Nails",
        "name": "Ripe (With Decay)",
        "youtubeId": "HkrUlSKRQRo",
        "halo": "The Fragile",
        "start": 283
    },
    {
        "date": "03/15/24",
        "artist": "Nine Inch Nails",
        "name": "Mr. Self Destruct",
        "youtubeId": "zBFXFzqIjHM",
        "halo": "The Downward Spiral",
        "start": 153
    },
    {
        "date": "03/16/24",
        "artist": "Nine Inch Nails",
        "name": "2 Ghosts I",
        "youtubeId": "T6R8GCX_wSs",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "03/17/24",
        "artist": "Nine Inch Nails",
        "name": "Heresy (Version)",
        "youtubeId": "higPeuBiI-8",
        "halo": "Further Down the Spiral",
        "start": 0
    },
    {
        "date": "03/18/24",
        "artist": "Nine Inch Nails",
        "name": "HYPERPOWER!",
        "youtubeId": "qt-AqmOVH74",
        "halo": "Year Zero",
        "start": 90
    },
    {
        "date": "03/19/24",
        "artist": "Nine Inch Nails",
        "name": "Terrible Lie",
        "youtubeId": "rSjPAwsEXMU",
        "halo": "Pretty Hate Machine",
        "start": 77
    },
    {
        "date": "03/20/24",
        "artist": "Nine Inch Nails",
        "name": "That's What I Get",
        "youtubeId": "NU4wlbT4nBw",
        "halo": "Pretty Hate Machine",
        "start": 82
    },
    {
        "date": "03/21/24",
        "artist": "Nine Inch Nails",
        "name": "Burn",
        "youtubeId": "UePiNA--ook",
        "halo": "Soundtrack NIN",
        "start": 164
    },
    {
        "date": "03/22/24",
        "artist": "Nine Inch Nails",
        "name": "And All That Could Have Been",
        "youtubeId": "2U0flA_Yp64",
        "halo": "Still",
        "start": 198
    },
    {
        "date": "03/23/24",
        "artist": "Nine Inch Nails",
        "name": "Down In It (Shred)",
        "youtubeId": "cBy4WUAf7UQ",
        "halo": "Down In It",
        "start": 0
    },
    {
        "date": "03/24/24",
        "artist": "Nine Inch Nails",
        "name": "8 Ghosts I",
        "youtubeId": "mB-epcA0fMM",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "03/25/24",
        "artist": "Nine Inch Nails",
        "name": "In This Twilight",
        "youtubeId": "aEYJVrGAlVY",
        "halo": "Year Zero",
        "start": 207
    },
    {
        "date": "03/26/24",
        "artist": "Nine Inch Nails",
        "name": "The Way Out Is Through",
        "youtubeId": "_WVA92lnty0",
        "halo": "The Fragile",
        "start": 159
    },
    {
        "date": "03/27/24",
        "artist": "Nine Inch Nails",
        "name": "Letting You",
        "youtubeId": "FGhnRVqrTWs",
        "halo": "The Slip",
        "start": 70
    },
    {
        "date": "03/28/24",
        "artist": "Nine Inch Nails",
        "name": "I'm Not From This World",
        "youtubeId": "9fjbcSUSt9w",
        "halo": "Bad Witch",
        "start": 163
    },
    {
        "date": "03/29/24",
        "artist": "Nine Inch Nails",
        "name": "Over and Out",
        "youtubeId": "h-XlN3N2fis",
        "halo": "Bad Witch",
        "start": 127
    },
    {
        "date": "03/30/24",
        "artist": "Nine Inch Nails",
        "name": "Sin (Long)",
        "youtubeId": "Xqekts7OyOI",
        "halo": "Sin",
        "start": 0
    },
    {
        "date": "03/31/24",
        "artist": "Nine Inch Nails",
        "name": "37 Ghosts",
        "youtubeId": "zxEC4hGdSWI",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "04/01/24",
        "artist": "Nine Inch Nails",
        "name": "Satellite",
        "youtubeId": "Tqymo0sVUwE",
        "halo": "Hesitation Marks",
        "start": 280
    },
    {
        "date": "04/02/24",
        "artist": "Nine Inch Nails",
        "name": "The Upward Twist",
        "youtubeId": "uNIt6-7zDS8",
        "halo": "Halo Negative One",
        "start": 537
    },
    {
        "date": "04/03/24",
        "artist": "Nine Inch Nails",
        "name": "Into The Void",
        "youtubeId": "Q8LEheSUTGE",
        "halo": "The Fragile",
        "start": 38
    },
    {
        "date": "04/04/24",
        "artist": "Nine Inch Nails",
        "name": "Dear World,",
        "youtubeId": "9bx-xlof6q4",
        "halo": "Not The Actual Events",
        "start": 171
    },
    {
        "date": "04/05/24",
        "artist": "Nine Inch Nails",
        "name": "10 Miles High",
        "youtubeId": "hmPQDbbgA1Y",
        "halo": "The Fragile",
        "start": 250
    },
    {
        "date": "04/06/24",
        "artist": "Nine Inch Nails",
        "name": "Survivalism_OpalHeartClinic_Niggy_Tardust!(Escaped\u2026",
        "youtubeId": "iRk4FFJVVFc",
        "halo": "Survivalism",
        "start": 0
    },
    {
        "date": "04/07/24",
        "artist": "Nine Inch Nails",
        "name": "Capital G (Ladytron)",
        "youtubeId": "OMBqjxOCviA",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "04/08/24",
        "artist": "Nine Inch Nails",
        "name": "The Frail",
        "youtubeId": "rOMcxTK7-0w",
        "halo": "The Fragile",
        "start": 59
    },
    {
        "date": "04/09/24",
        "artist": "Nine Inch Nails",
        "name": "While I'm Still Here",
        "youtubeId": "ckdZ7E-vP9M",
        "halo": "Hesitation Marks",
        "start": 63
    },
    {
        "date": "04/10/24",
        "artist": "Nine Inch Nails",
        "name": "Even Deeper",
        "youtubeId": "hwz86XHCMdU",
        "halo": "The Fragile",
        "start": 305
    },
    {
        "date": "04/11/24",
        "artist": "Nine Inch Nails",
        "name": "Only",
        "youtubeId": "VB8iRUqlpZw",
        "halo": "With Teeth",
        "start": 163
    },
    {
        "date": "04/12/24",
        "artist": "Nine Inch Nails",
        "name": "March Of The Pigs",
        "youtubeId": "ojk-YRMXSdc",
        "halo": "The Downward Spiral",
        "start": 86
    },
    {
        "date": "04/13/24",
        "artist": "Nine Inch Nails",
        "name": "13 Ghosts II",
        "youtubeId": "s_BNUwxAAGQ",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "04/14/24",
        "artist": "Nine Inch Nails",
        "name": "The Perfect Drug (Spacetime Continuum)",
        "youtubeId": "2t01jpcBmDY",
        "halo": "The Perfect Drug Versions",
        "start": 0
    },
    {
        "date": "04/15/24",
        "artist": "Nine Inch Nails",
        "name": "999,999",
        "youtubeId": "JOUBNVtg200",
        "halo": "The Slip",
        "start": 28
    },
    {
        "date": "04/16/24",
        "artist": "Nine Inch Nails",
        "name": "The New Flesh",
        "youtubeId": "1bl7QovPblA",
        "halo": "The Fragile",
        "start": 155
    },
    {
        "date": "04/17/24",
        "artist": "Nine Inch Nails",
        "name": "The Four of Us Are Dying",
        "youtubeId": "_aaqlfVLT1Y",
        "halo": "The Slip",
        "start": 115
    },
    {
        "date": "04/18/24",
        "artist": "Nine Inch Nails",
        "name": "Echoplex",
        "youtubeId": "_c7ixDZj9DA",
        "halo": "The Slip",
        "start": 213
    },
    {
        "date": "04/19/24",
        "artist": "Nine Inch Nails",
        "name": "Underneath It All",
        "youtubeId": "pzXeJ762BBc",
        "halo": "The Fragile",
        "start": 48
    },
    {
        "date": "04/20/24",
        "artist": "Nine Inch Nails",
        "name": "Find My Way (Oneohtrix Point Never Remix)",
        "youtubeId": "51xrIjki5dg",
        "halo": "Hesitation Marks (Deluxe)",
        "start": 0
    },
    {
        "date": "04/21/24",
        "artist": "Nine Inch Nails",
        "name": "4 Ghosts I",
        "youtubeId": "M72X-mXd-kA",
        "halo": "Ghosts I-IV",
        "start": 0
    },
    {
        "date": "04/22/24",
        "artist": "Nine Inch Nails",
        "name": "All Time Low",
        "youtubeId": "BoTF67O5jx4",
        "halo": "Hesitation Marks",
        "start": 9
    },
    {
        "date": "04/23/24",
        "artist": "Nine Inch Nails",
        "name": "Play the Goddamned Part",
        "youtubeId": "85UgvBkMfr8",
        "halo": "Bad Witch",
        "start": 130
    },
    {
        "date": "04/24/24",
        "artist": "Nine Inch Nails",
        "name": "My Violent Heart",
        "youtubeId": "hqRk5kXROS4",
        "halo": "Year Zero",
        "start": 196
    },
    {
        "date": "04/25/24",
        "artist": "Nine Inch Nails",
        "name": "Physical (You're So)",
        "youtubeId": "nOvX75ck1_E",
        "halo": "Broken",
        "start": 40
    },
    {
        "date": "04/26/24",
        "artist": "Nine Inch Nails",
        "name": "Meet Your Master",
        "youtubeId": "P9MqBx3nc0M",
        "halo": "Year Zero",
        "start": 93
    },
    {
        "date": "04/27/24",
        "artist": "Nine Inch Nails",
        "name": "While I'm Still Here (Breyer P-Orridge 'Howler' Remix)",
        "youtubeId": "-YjpJfLB6xk",
        "halo": "Hesitation Marks (Deluxe)",
        "start": 0
    },
    {
        "date": "04/28/24",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole (Opal)",
        "youtubeId": "uLKJzDixrvw",
        "halo": "Head Like A Hole",
        "start": 0
    },
    {
        "date": "04/29/24",
        "artist": "Nine Inch Nails",
        "name": "Lights In The Sky",
        "youtubeId": "j-jWOBPN4UU",
        "halo": "The Slip",
        "start": 85
    },
    {
        "date": "04/30/24",
        "artist": "Nine Inch Nails",
        "name": "Reptile",
        "youtubeId": "4d64sKlm-Wo",
        "halo": "The Downward Spiral",
        "start": 363
    },
    {
        "date": "05/01/24",
        "artist": "Nine Inch Nails",
        "name": "Shit Mirror",
        "youtubeId": "yeqjz5mXrLQ",
        "halo": "Bad Witch",
        "start": 170
    },
    {
        "date": "05/02/24",
        "artist": "Nine Inch Nails",
        "name": "Head Like A Hole",
        "youtubeId": "ghIRu6UvQPc",
        "halo": "Pretty Hate Machine",
        "start": 206
    },
    {
        "date": "05/03/24",
        "artist": "Nine Inch Nails",
        "name": "Sanctified",
        "youtubeId": "pIyTpWyeYy0",
        "halo": "Pretty Hate Machine",
        "start": 333
    },
    {
        "date": "05/04/24",
        "artist": "Nine Inch Nails",
        "name": "No You Don't (Version)",
        "youtubeId": "Y_7vJHs3HmM",
        "halo": "The Fragile Hillebrandt Mixes",
        "start": 0
    },
    {
        "date": "05/05/24",
        "artist": "Nine Inch Nails",
        "name": "Meet Your Master (The Faint)",
        "youtubeId": "PrVwjnXSuV0",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "05/06/24",
        "artist": "Nine Inch Nails",
        "name": "Sunspots",
        "youtubeId": "dOJpXttqA3c",
        "halo": "With Teeth",
        "start": 155
    },
    {
        "date": "05/07/24",
        "artist": "Nine Inch Nails",
        "name": "Non-Entity",
        "youtubeId": "Bg76mSSzErs",
        "halo": "NINJA 2009 Summer Tour EP",
        "start": 190
    },
    {
        "date": "05/08/24",
        "artist": "Nine Inch Nails",
        "name": "Kinda I Want To",
        "youtubeId": "1UaqcWBtT-o",
        "halo": "Pretty Hate Machine",
        "start": 233
    },
    {
        "date": "05/09/24",
        "artist": "Nine Inch Nails",
        "name": "Another Version Of The Truth",
        "youtubeId": "dJae6JW7LLQ",
        "halo": "Year Zero",
        "start": 225
    },
    {
        "date": "05/10/24",
        "artist": "Nine Inch Nails",
        "name": "Came Back Haunted",
        "youtubeId": "h6iKmf6nbLk",
        "halo": "Hesitation Marks",
        "start": 180
    },
    {
        "date": "05/11/24",
        "artist": "Nine Inch Nails",
        "name": "Capital G (Switch)",
        "youtubeId": "IG4OsdagwXc",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 3
    },
    {
        "date": "05/12/24",
        "artist": "Nine Inch Nails",
        "name": "The Hand That Feeds (Photek Dub Mix)",
        "youtubeId": "oAWecyQq9M0",
        "halo": "The Hand That Feeds",
        "start": 0
    },
    {
        "date": "05/13/24",
        "artist": "Nine Inch Nails",
        "name": "Ahead of Ourselves",
        "youtubeId": "4Ab1O-i4ep4",
        "halo": "Bad Witch",
        "start": 140
    },
    {
        "date": "05/14/24",
        "artist": "Nine Inch Nails",
        "name": "Beside You In Time",
        "youtubeId": "dO12h46EOyQ",
        "halo": "With Teeth",
        "start": 170
    },
    {
        "date": "05/15/24",
        "artist": "Nine Inch Nails",
        "name": "Piggy",
        "youtubeId": "SSKp94wMEu0",
        "halo": "The Downward Spiral",
        "start": 186
    },
    {
        "date": "05/16/24",
        "artist": "Nine Inch Nails",
        "name": "The Line Begins To Blur",
        "youtubeId": "lmygz88y348",
        "halo": "With Teeth",
        "start": 100
    },
    {
        "date": "05/17/24",
        "artist": "Nine Inch Nails",
        "name": "Survivalism",
        "youtubeId": "Kr-uzvTBAlY",
        "halo": "Year Zero",
        "start": 126
    },
    {
        "date": "05/18/24",
        "artist": "Nine Inch Nails",
        "name": "The Beginning Of The End (Ladytron)",
        "youtubeId": "Bzl348HCN0w",
        "halo": "Y34RZ3R0R3M1X3D",
        "start": 0
    },
    {
        "date": "05/19/24",
        "artist": "Nine Inch Nails",
        "name": "La Mer (Version)",
        "youtubeId": "KfGOxWYYEVY",
        "halo": "The Fragile Hillebrandt Mixes",
        "start": 0
    },
    {
        "date": "05/20/24",
        "artist": "Nine Inch Nails",
        "name": "Wish",
        "youtubeId": "IU5R8EKsomA",
        "halo": "Broken",
        "start": 88
    },
    {
        "date": "05/21/24",
        "artist": "Nine Inch Nails",
        "name": "Discipline",
        "youtubeId": "wHxOiAz4NF8",
        "halo": "The Slip",
        "start": 158
    },
    {
        "date": "05/22/24",
        "artist": "Nine Inch Nails",
        "name": "Not So Pretty Now",
        "youtubeId": "LWdn9QYN4ag",
        "halo": "NINJA 2009 Summer Tour EP",
        "start": 158
    }
]
;
