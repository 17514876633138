import React from "react";
import { IoMusicalNoteOutline, IoHelpCircleOutline } from "react-icons/io5";
import { Button } from "..";
import { appName } from "../../components";

import * as Styled from "./index.styled";

interface Props {
    onClose: () => void;
}

export function InfoPopUp({ onClose }: Props) {
    return (
        <Styled.Container>
            <Styled.PopUp>
                <h1>Hey Pig! 🐷</h1>
                <Styled.Spacer />
                <Styled.Section>
                    <p>
                        Yeah, you! Step right up to {appName}, a musical guessing game for NIN nerds.
                        <br /><br />
                        Weekend games are intended to be harder than weekday games, so go study those remix albums! Songs switch at midnight in your local time.
                        <br /><br />
                        Unlike other Heardle games, Nailed Heardle games may start anywhere in a song, not just the beginning.
                        <br /><br />
                        Thanks to <a href="https://github.com/Shizerq/sluchajfun" target="_blank">Słuchaj.fun</a>, the open source project off of which this was built.
                    </p>
                </Styled.Section>
                <Button variant="green" style={{ marginTop: 20 }} onClick={onClose}>
                    March!
                </Button>
                <Styled.Contact>
                    Please reach out on the Discord if you have any feedback or questions.
                </Styled.Contact>
            </Styled.PopUp>
        </Styled.Container>
    );
}
